import { Component } from "@angular/core";
import { getApp } from "../../app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Row } from "../../tree/tree.util";
import { Router } from "@angular/router";

@Component({
  selector: "app-list-opened-cases",
  templateUrl: "./list-opened-cases.component.html",
  styleUrls: ["./list-opened-cases.component.scss"],
})
export class ListOpenedCasesComponent {
  app = getApp((app) => {
    this.app = app;
  });
  columns: string[] = [];
  partRow: boolean = false;
  canCreateCase: boolean = false;

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit() {
    this.getDisplayedColumns();
    if (this.app.tree.isPartRow(this.app.treeRow.currentRow as Row)) {
      this.partRow = true;
    }
    if (!this.router.url.includes("parts")) {
      this.canCreateCase = true;
    } else {
      this.canCreateCase = false;
    }
  }

  getDisplayedColumns() {
    let columns = new Set<string>();

    this.app.list.thread.boxesOnListOfOpenedCases.forEach((item) => {
      item = item.split(".")[1];
      columns.add(item);
    });
    this.columns = Array.from(columns);
  }

  routeToThreadDetails(omfNumber: string) {
    let currentUrl =
      "/" + this.app.customers.expectCurrent + "/thread/" + omfNumber;
    window.open(currentUrl, "_blank");
    // this.app.routing.navigateThread(omfNumber);
  }

  routeToNewCase() {
    this.modalService.dismissAll();
    if (this.app.type === "part") {
      //used when create new cases from tree
      this.app.treeRow.newCase(this.app.treeRow.currentRow);
    }
    if (this.app.type === "alert") {
      this.app.thread.newCase(this.app.alerts.currentSelected);
    }
    if (this.app.type === "manufacturer") {
      this.app.thread.newCase(this.app.manufacturersFilter.currentManufacturer);
    }
    if (this.app.type === "seMatch") {
      this.app.seFilter.newCase(this.app.seFilter.selectedMatch);
    }
  }
}
