import { ComponentSettings, FieldTypes, empty } from "../settings/settings";

export const field = {
  name: {
    type: FieldTypes.value,
    text: { de: "Name", en: "Name", nl: "Naam" },
    mutable: true,
    required: true,
  },
  email: {
    type: FieldTypes.value,
    text: { de: "Email", en: "Email", nl: "e-mail" },
    regex: "^[0-9a-zA-Z]+(?:.[0-9a-zA-Z]+)*@[a-zA-Z0-9]{2,}(?:.[a-zA-z]{2,})+$",
  },
  roles: {
    type: FieldTypes.options,
    text: { de: "Rollen", en: "Roles", nl: "Rollen" },
    multiple: false,
    required: true,
    options: {
      admin: {
        text: {
          de: "Administrator",
          en: "Admin",
          nl: "Beheerder",
        },
      },
      analyst: {
        text: {
          de: "Analyst",
          en: "Analyst",
        },
      },
      guest: {
        text: {
          de: "Gast",
          en: "Guest",
          nl: "Gast",
        },
      },
      team: {
        text: {
          de: "Team",
          en: "Team",
          nl: "Team",
        },
      },
      user: {
        text: {
          de: "Benutzer",
          en: "User",
          nl: "Gebruiker",
        },
      },
      RMAdmin: {
        text: {
          de: "RM Admin",
          en: "RM Admin",
          nl: "RM Admin",
        },
      },
    },
  },
  jobTitle: {
    type: FieldTypes.options,
    text: { de: "Funktion", en: "Function", nl: "Functie" },
    multiple: false,
    options: {},
  },
  oldPassword: {
    text: { de: "Altes Passwort", en: "Old Password", nl: "Oud wachtwoord" },
    persist: true,
    type: FieldTypes.password,
    search: false,
  },
  password: {
    text: { de: "Passwort", en: "Password", nl: "Wachtwoord" },
    type: FieldTypes.password,
    required: true,
    search: false,
  },
  retypePassword: {
    text: {
      de: "Passwort wiederholen",
      en: "Retype password",
      nl: "Wachtwoord herhalen",
    },
    type: FieldTypes.password,
    required: true,
    search: false,
  },

  businessArea: {
    type: FieldTypes.options,
    text: {
      de: "Geschäftsfelder",
      en: "Business Area",
      nl: "Bedrijfssectoren",
    },
    options: {
      REGIO: {
        text: {
          de: "Regio",
          en: "Regio",
          nl: "Regio",
        },
      },
      CARGO: {
        text: {
          de: "Cargo",
          en: "Cargo",
        },
      },
      FERNVERKEHR: {
        text: {
          de: "Fernverkehr",
          en: "Long-distance",
          nl: "Intercity",
        },
      },
      FZ: {
        text: {
          de: "FZI",
          en: "FZI",
        },
      },
      SONSTIGE: {
        text: {
          de: "Sonstige",
          en: "Other",
          nl: "Overige",
        },
      },
    },
    search: false,
  },
  active: {
    text: { de: "Aktiv", en: "Active", nl: "Actief" },
    type: FieldTypes.checkbox,
  },
  passwordExpireDate: {
    type: FieldTypes.date,
    text: {
      de: "Passwort gültig bis",
      en: "Password valid until",
      nl: "Wachtwoord geldig tot",
    },
    search: false,
  },
  displayAsComponent: {
    text: {
      de: "Komponent Verantwortlicher?",
      en: "Display as component responsible?",
      nl: "Component verantwoordelijk?",
    },
    type: FieldTypes.checkbox,
    search: false,
  },
  lastChangedPassword: {
    type: FieldTypes.timestamp,
    text: {
      de: "Passwort geändert",
      en: "Password changed",
      nl: "Wachtwoord gewijzigd",
    },
    search: false,
  },
  forcePasswordChangeOnFirstLogin: {
    text: {
      de: "Passwortänderung bei der ersten Anmeldung erzwingen",
      en: "Force password change on first login",
      nl: "Wachtwoord wijziging afdwingen bij de eerste login",
    },
    type: FieldTypes.checkbox,
    search: false,
  },
  passwordNeverExpire: {
    text: {
      de: "Password läuft nie ab",
      en: "Password does not expire",
      nl: "Wachtwoord vervalt nooit",
    },
    type: FieldTypes.checkbox,
    search: false,
  },
  remember: {
    type: FieldTypes.checkbox,
    text: {
      de: "Eingeloggt bleiben",
      en: "Keep me signed in",
      nl: "Blijf ingelogd",
    },
    search: false,
  },
  lastFivePasswords: {
    type: FieldTypes.data,
    text: {
      de: "Last five passwords",
      en: "Last five passwords",
      nl: "Last five passwords",
    },
    search: false,
  },
  projectNumber: {
    type: FieldTypes.options,
    text: {
      de: "Projekt Nr.",
      en: "Project Nr.",
      nl: "Project Nr.",
    },
    options: {
      PUMA: {
        text: {
          de: "SPz PUMA",
          en: "SPz PUMA",
          nl: "SPz PUMA",
        },
      },
      BOXER: {
        text: {
          de: "Boxer",
          en: "Boxer",
          nl: "Boxer",
        },
      },
      DINGO: {
        text: {
          de: "Dingo2L",
          en: "Dingo2L",
          nl: "Dingo2L",
        },
      },
      LEO: {
        text: {
          de: "KPz Leopard 2",
          en: "KPz Leopard 2",
          nl: "KPz Leopard 2",
        },
      },
    },
    search: false,
  },
};

const settings = {
  permission: {
    manage: false,
    editOwn: false,
    addExpiryDate: false,
  },
  list: {
    columns: empty,
    boxes: empty,
    main: empty,
    personProfile: empty,
    readonlyView: [],
    fieldsOnFilter: empty,
    filterBoxesUser: empty,
    usersCsvExportColumns: empty,
  },
  url: {},
  text: {
    user: { de: "Benutzer", en: "User", nl: "Gebruiker" },
    add: {
      de: "Neuen Benutzer anlegen",
      en: "Add new user",
      nl: "Nieuwe gebruiker aanmaken",
    },
    main: {
      de: "Benutzer (Details)",
      en: "User (details)",
      nl: "Gebruiker (details)",
    },
    save: {
      de: "Änderungen übernehmen",
      en: "Save changes",
      nl: "Veranderingen bewaren",
    },
    close: {
      de: "Detailansicht schließen",
      en: "Close detail view",
      nl: "Detailweergave sluiten",
    },
    delete: {
      de: "Benutzer löschen",
      en: "Delete user",
      nl: "Gebruiker verwijderen",
    },
    deactivateUser: {
      de: "Benutzer deaktivieren",
      en: "Deactivate User",
      nl: "Gebruiker deactiveren",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard changes",
      nl: "Annuler de wijzigingen",
    },
    deleteMessage: {
      de: "Möchten Sie diesen Benutzer wirklich entfernen?",
      en: "Do you really want to remove this user?",
      nl: "Do you really want to remove this user?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    messageInvalidForm: {
      de: "Die folgenden Felder sind ungültig:",
      en: "The following fields are invalid:",
      nl: "De volgende velden zijn ongeldig:",
    },
    errorRetypedPassword: {
      de: "Die Passwörter stimmen nicht überein.",
      en: "Retyped password is different.",
      nl: "Opnieuw getypt wachtwoord is anders.",
    },
    successUserCreated: {
      de: "Benutzer wurde erfolgreich angelegt.",
      en: "User created successfully.",
      nl: "Gebruiker succesvol aangemaakt.",
    },
    succesUserUpdated: {
      de: "Benutzer erfolgreich aktualisiert",
      en: "User updated successfully",
      nl: "Gebruiker succesvol bijgewerkt.",
    },
    userAlreadyExists: {
      de: "Benutzer existiert bereits",
      en: "User already exists",
      nl: "Gebruiker bestaat al.",
    },
    usernameNotAccepted: {
      de: "Ungültiger Benutzername! Die einzigen akzeptierten Sonderzeichen sind - und _",
      en: "Invalid user name! The only accepted special characters are - and _",
      nl: "Ongeldige gebruikersnaam! De enige toegestane speciale tekens zijn - en _",
    },
    passwordNotString: {
      de: "Das Passwort ist keine Zeichenfolge",
      en: "Password is not a string",
      nl: "Wachtwoord is geen string.",
    },
    passwordCount1: {
      de: "Das Passwort ist nicht mindestens ",
      en: "Password is not at least ",
      nl: "Wachtwoord is niet ten minste ",
    },
    passwordCount2: {
      de: " Zeichen lang",
      en: " characters long",
      nl: " tekens lang",
    },
    passwodSpecialCharacters: {
      de: "Das Passwort enthält keines der folgenden Sonderzeichen: ",
      en: "Password does not contain one of the following special characters: ",
      nl: "Wachtwoord bevat niet een van de volgende speciale tekens: ",
    },
    passwordUsed: {
      de: "Das Passwort wurde bereits verwendet. Bitte verwenden Sie ein anderes!",
      en: "Password has been used before. Please use another one!",
      nl: "Wachtwoord is al eerder gebruikt. Gebruik alstublieft een andere!",
    },
    passwordNumber: {
      de: "Das Passwort enthält keine Nummer",
      en: "Password does not contain a number",
      nl: "Wachtwoord bevat geen cijfer.",
    },
    users: {
      en: "User Management",
      de: "Benutzermanagement",
      nl: "Gebruikersbeheer ",
    },
    create: {
      de: "Neuen Benutzer anlegen",
      en: "Create new user",
      nl: "Nieuwe gebruiker aanmaken",
    },
    edit: {
      de: "Speichern",
      en: "Save",
      nl: "Bewaar",
    },
    discardBtn: {
      de: "Änderungen",
      en: "Discard",
      nl: "Annuler",
    },
    closeBtn: { de: "Schließen", en: "Close" },
    userList: { de: "Benutzerliste", en: "User List", nl: "User List" },
  },
  field,
};

export const user: ComponentSettings<typeof settings> = settings;
