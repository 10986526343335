import { AppType } from "../app.type";
import { getApp } from "../app";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { getMPNBySeId } from "../api.service";

@Component({
  selector: "app-assigned-to",
  templateUrl: "./assigned-to.component.html",
  styleUrls: ["./assigned-to.component.scss"],
})
export class AssignedToComponent implements OnInit, OnDestroy {
  app = getApp((app: AppType) => {
    this.app = app;
  });
  assignedCPNs: string[] = [];

  constructor() {}

  ngOnInit() {
    this.setAssignedCPNs();
  }

  async setAssignedCPNs() {
    let manufacturersBySeId = await getMPNBySeId(
      this.app.manufacturer.selectedManufacturer.ComID,
      this.app.customers.expectCurrent
    );

    manufacturersBySeId.forEach((mpn: any) => {
      if (
        mpn.manufacturerPartNumber ==
          this.app.manufacturer.selectedManufacturer["PartNumber"] &&
        mpn.name ==
          this.app.manufacturer.selectedManufacturer["Manufacturer"] &&
        !this.assignedCPNs.includes(mpn.partNumber)
      ) {
        this.assignedCPNs.push(mpn.partNumber);
      }
    });
  }

  ngOnDestroy(): void {}
}
