import { Component, ViewChild, Output, OnInit, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { bulkDocsByType, doc2Model, model2Doc } from "../api.service";
import { getApp } from "../app";
import { FieldTypes } from "../../../../shared/settings/settings";

@Component({
  selector: "app-create-manufacturer-modal",
  templateUrl: "./create-manufacturer-modal.component.html",
  styleUrls: ["./create-manufacturer-modal.component.scss"],
})
export class CreateManufacturerModalComponent implements OnInit, OnDestroy {
  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });
  cleanupFields: Set<any> = new Set();
  columns: string[] = this.app.list.manufacturer.fieldsOnCreate;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  async open() {
    /** set required/mutable fields based on the view */
    // if (this.app.state.createFromPart) {
    //   this.app.field.getFieldSettings("manufacturer.partNumber").mutable =
    //     false;
    // }
    this.app.field.getFieldSettings("manufacturer.partNumber").required = true;
    this.app.field.getFieldSettings("manufacturer.nameRaw").required = true;
    this.app.field.getFieldSettings(
      "manufacturer.manufacturerPartNumberRaw"
    ).required = true;
    this.app.field.changeFieldSettings(
      "manufacturer.partNumber",
      FieldTypes.typeahead
    );

    /** */
    try {
      this.app.createManufacturer.setModel();
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  async save() {
    let doc = model2Doc("manufacturer", this.app.createManufacturer.model);
    doc.partNumber =
      this.app.part.id != null
        ? this.app.part.id
        : this.app.createManufacturer.model["manufacturer.partNumber"];

    let manufacturerDoc = new Manufacturer();

    Object.keys(doc).forEach((key: string) => {
      manufacturerDoc[key] = (doc as any)[key];
    });

    manufacturerDoc["manufacturerPartNumber"] =
      manufacturerDoc["manufacturerPartNumberRaw"] != null
        ? manufacturerDoc["manufacturerPartNumberRaw"]
        : "";
    manufacturerDoc["manufacturerPartDescription"] =
      manufacturerDoc["manufacturerPartDescriptionRaw"] != null
        ? manufacturerDoc["manufacturerPartDescriptionRaw"]
        : "";
    manufacturerDoc["name"] =
      manufacturerDoc["nameRaw"] != null ? manufacturerDoc["nameRaw"] : "";
    manufacturerDoc["valid"] =
      manufacturerDoc["valid"] != null ? manufacturerDoc["valid"] : true;
    manufacturerDoc["obsolescenceStatusOverride"] =
      doc["obsolescenceStatusOverride"] != null
        ? doc["obsolescenceStatusOverride"]
        : false;
    manufacturerDoc.type = "manufacturer";
    manufacturerDoc["create_time"] = new Date().getTime();
    delete manufacturerDoc._id;
    this.app.manufacturer.currentManufacturer = doc2Model(
      "manufacturer",
      manufacturerDoc
    ) as Manufacturer;
    await this.app.manufacturer.save();

    this.app.createManufacturer.model = {};
    this.app.manufacturer.selectedManufacturer = new Manufacturer();
    this.app.state.createFromPart = true;
    // emit a subject to make sure manufacturers list gets updated on the detail-table component
    this.app.manufacturer.manufacturerAction.next(true);
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.manufacturer.createdSuccesfully,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 3000);
    sessionStorage.setItem(
      "se_filter_cpn",
      this.app.seFilter.searchedPartNumber
    );
    this.app.seFilter.redoSearch.next(true);
    this.app.model["manufacturer.partNumber"] = "";
    this.app.typeAheadBasic.model["manufacturer.partNumber"] = "";
    this.modalService.dismissAll();
  }

  hasRequiredFields() {
    let result: boolean = true;
    if (
      !StringUtils.isNullOrEmpty(
        this.app.createManufacturer.model["manufacturer.partNumber"]
      ) &&
      !StringUtils.isNullOrEmpty(
        this.app.createManufacturer.model["manufacturer.nameRaw"]
      ) &&
      !StringUtils.isNullOrEmpty(
        this.app.createManufacturer.model[
          "manufacturer.manufacturerPartNumberRaw"
        ]
      )
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }
  ngOnDestroy(): void {
    this.app.field.resetFieldType("manufacturer.partNumber");
  }
}
