import { Credits } from "./credits";
import {
  ObsolescenceStatus,
  ObsolescencelifeCycleRisk,
  EuRoHSStatus,
  ReachAffectedStatus,
} from "./rm-common-properties";

export type MineralStatus =
  | ""
  | "drc"
  | "ndrc"
  | "partialUndeterminable"
  | "undeterminable"
  | "unknown";

export class SeUserInfo {
  CreationDate: string = "";
  ExpirationDate: string = "";
  PartDetailLimit: string = "";
  PartDetailCount: string = "";
  PartDetailRemaining: string = "";
  AclPartsCount: string = "";
}

export class SeSearchPart {
  partNumber: string = "";
  manufacturer?: string = "";
}

export class SeMatch {
  [key: string]: any;
  ComID: string = "";
  PartNumber: string = "";
  Manufacturer: string = "";
  PlName: string = "";
  Description: string = "";
  Datasheet: string = "";
  Lifecycle: string = "";
  RoHS: string = "";
  RoHSVersion: string = "";
  MatchRating: string = "";
  TaxonomyPath: string = "";
  TaxonomyPathID: string = "";
  YEOL: string = "";
  type?: string = "seMatch";
  manufacturerPartNumber?: string = "";
}

export type Multi<T> = T | T[];

export class SeMatchingResult {
  RequestedPart: string = "";
  RequestedManufacturer: string = "";
  PartList: Multi<{ PartDto: Multi<SeMatch> }> = { PartDto: new SeMatch() };
}

export class SummaryData {
  EURoHS: string = "";
  OtherSources: string = "";
  SupplierCageCode: string = "";
  PartDescription: string = "";
  TaxonomyPath: string = "";
  PartNumber: string = "";
  Manufacturer: string = "";
  ECCN: string = "";
  Datasheet: string = "";
  IntroductionDate: string = "";
  LastCheckDate: string = "";
  ScheduleB: string = "";
  CountriesOfOrigin: {
    CountryOfOrigin: CountryOfOrigin;
  } = { CountryOfOrigin: new CountryOfOrigin() };
}
class CountryOfOrigin {
  [key: string]: any;
  Country: string = "";
}

export class LifeCycleData {
  PartStatus: string = "";
  LTBDate: string = "";
  Source: string = "";
  EstimatedYearsToEOL: string = "";
  EstimatedEOLDate: string = "";
  PartLifecycleStage: string = "";
  MinimumEstimatedYearsToEOL: string = "";
  MaximumEstimatedYearsToEOL: string = "";
  LifeCycleRiskGrade: string = "";
  OverallRisk: string = "";
  PartLifecycleCode: string = "";
}

export class EnvironmentalDto {
  RoHSVersion: string = "";
  Source: string = "";
  ConflictMineralStatus: string = "";
  ConflictMineralStatement: string = "";
  ChinaRoHS: ChinaRoHS = new ChinaRoHS();
  TSCA: TSCA = new TSCA();
  CaliforniaProposition65Dto: CaliforniaProposition =
    new CaliforniaProposition();
  GlobalPFASAssessment: GlobalPFASAssessment = new GlobalPFASAssessment();
}

export class ChinaRoHS {
  ChinaRoHSStatus: string = "";
  ChinaRoHSDeclaration: string = "";
}
export class TSCA {
  TSCAStatus: string = "";
  TSCADeclaration: string = "";
}

export class CaliforniaProposition {
  Status: string = "";
  Source: string = "";
}

export class GlobalPFASAssessment {
  Status: string = "";
  Source: string = "";
}

export class PackageData {
  SupplierPackage: string = "";
}

export class Feature {
  FeatureName: string = "";
  FeatureValue: string = "";
  FeatureUnit: string = "";
  FeatureID: string = "";
}

export class ParametricData {
  Features: Feature[] = [];
}

export class Replacement {
  ReplacementComId: string = "";
  ReplacementPartNumber: string = "";
  ReplacementType: string = "";
}
export class Cross {
  CrossComId: string = "";
  CrossPartNumber: string = "";
  CrossManufacturer: string = "";
  CrossType: string = "";
  Comment: string = "";
}

export class ReachDto {
  [key: string]: any;
  CachedSource: string = "";
  ContainsSVHC: string = "";
}
export class PCNDto {
  TypeOfChange: string = "";
  NotificationDate: string = "";
  EffectiveDate: string = "";
  PcnSource: string = "";
}

export class PcnData {
  [key: string]: any;
  pcnIssueDate?: string = "";
  pcnDescription?: string = "";
  pcnTypeOfChange?: string = "";
  pcnID?: string = "";
  pcnSource?: string = "";
  pcnEffectiveDate?: string = "";
}
export class FranchisedInventoryDto {
  BuyNowLink: string = "";
  Distributor: string = "";
  LastUpdated: string = "";
  Quantity: string = "";
}
export class FranchisedInventoryData {
  [key: string]: any;
  buyNowLink: string = "";
  distributor: string = "";
  lastUpdated: string = "";
  quantity: string = "";
}
export class SeDetail {
  SummaryData: SummaryData = new SummaryData();
  LifeCycleData: LifeCycleData = new LifeCycleData();
  EnvironmentalDto: EnvironmentalDto = new EnvironmentalDto();
  PackageData: PackageData = new PackageData();
  ParametricData: ParametricData = new ParametricData();
  ReplacementData?: {
    ReplacementDto?: Replacement[];
  } = { ReplacementDto: [] };
  CrossData?: {
    CrossDto: Cross[];
  } = { CrossDto: [] };
  PCNData: {
    PCNDto: {
      LastEOLSource: string;
    };
  } = { PCNDto: { LastEOLSource: "" } };
  ProductImage: {
    ProductImageLarge: string;
  } = { ProductImageLarge: "" };
  ReachData: {
    ReachDto: ReachDto;
  } = { ReachDto: new ReachDto() };
  PricingData: {
    AveragePrice: string;
    Maxleadtime: string;
    Minleadtime: string;
  } = { AveragePrice: "", Maxleadtime: "", Minleadtime: "" };
  PCNDetails: {
    PCNDto: PCNDto[];
  } = { PCNDto: [] };
  FranchisedInventoryData: {
    FranchisedInventoryDto: FranchisedInventoryDto[];
  } = { FranchisedInventoryDto: [] };
}

export class ManufacturerData extends Credits {
  name: string = "";
  nameRaw?: string = "";
  nameClean?: string = "";
  code?: string = "";
  partNumber: string = "";
  manufacturerPartNumber: string = "";
  manufacturerPartNumberRaw?: string = "";
  manufacturerPartNumberClean?: string = "";
  manufacturerPartDescription: string = "";
  manufacturerPartDescriptionRaw?: string = "";
  manufacturerPartDescriptionClean?: string = "";
  purchasePartNumber?: string = "";
  active: string = "false";
  valid: boolean = true;
  obsolescenceDate?: string = "";
  yearsToEndOfLife?: string = "";
  startOfProduction?: string = "";
  endOfProduction?: string = "";
  endOfSale?: string = "";
  seId?: string = "";
  matches?: SeMatch[] = [];
  edited?: boolean = false;
  numberOfMatches?: string = "";
  estimatedYearsToEOL?: string = "";
  estimatedEOLDate?: string = "";
  lifeCycleRisk?: string = "";
  ltbDate?: string = "";
  cage?: string = "";
  risk?: string = "";
  euRoHSStatus?: string = "";
  euRoHSVersion?: string = "";
  eolLink?: string = "";
  pcnHistory?: string = "";
  lastPCNDate?: string = "";
  lastPCNNumber?: string = "";
  lastEOLDate?: string = "";
  searchResults?: boolean = false;
  package?: string = "";
  packageDiameter?: string = "";
  packageLength?: string = "";
  packageWidth?: string = "";
  packageHeight?: string = "";
  encryptionStandard?: string = "";
  sources?: string = "";
  taxonomy?: string = "";
  lifecycleCode?: string = "";
  conformityCertificate?: string = "";
  datasheet?: string = "";
  lastCheckDate?: string = "";
  seStatus?: string = "";
  oldSeStatus?: string = "";
  eccn?: string = "";
  minTemp?: string = "";
  maxTemp?: string = "";
  crossType?: string = "";
  differences?: string = "";
  replacementType?: string = "";
  conflictMineralStatus?: string = "";
  conflictMineralStatement?: string = "";
  chinaRoHSStatus?: string = "";
  chinaRoHSDeclaration?: string = "";
  tscaStatus?: string = "";
  tscaSource?: string = "";
  tscaDeclaration?: string = "";
  image?: string = "";
  productEOSR?: string = "";
  productYearsToEOSR?: string = "";
  likelihood_yteol?: string = "";
  likelihood_yteosr?: string = "";
  countryOfOrigin?: string = "";
  customsTariff?: string = "";
  reach?: string = "";
  /**List of Substances of Very High Concern */
  svhcItems?: SVHCItem[] = [];
  svhcListVersion?: string = "";
  materialDeclaration?: string = "";
  environmentalLink?: string = "";
  averagePrice?: string = "";
  pcnNotificationDate?: string = "";
  lastUpdatedOn?: string = "";
  maxWeeks?: string = "";
  minWeeks?: string = "";
  partner?: string = "";
  exemption?: string = "";
  expirationDate?: string = "";
  designType?: string = "";
  sapStatus?: string = "";
  stockRange?: number = -1;
  euRoHS?: EuRoHSStatus = "";
  obsolescenceStatus?: ObsolescenceStatus = "";
  obsolescenceStatusOverride: boolean = false;
  obsolescenceStatus2years?: ObsolescenceStatus = "";
  obsolescenceStatus4years?: ObsolescenceStatus = "";
  obsolescenceStatus6years?: ObsolescenceStatus = "";
  obsolescenceStatus8years?: ObsolescenceStatus = "";
  likelihood?: ObsolescencelifeCycleRisk = "";
  impact?: ObsolescencelifeCycleRisk = "";
  totalRisk?: ObsolescencelifeCycleRisk = "";
  reachSubstances?: string = "";
  reachAffected?: ReachAffectedStatus;
  leadTime?: ObsolescencelifeCycleRisk = "";
  materialName?: string = "";
  materialClass?: string = "";
  supplierPending?: boolean = false;
  instructions?: string = "";
  pfasStatus?: string = "";
  pfasDeclaration?: string = "";
  californiaStatus?: string = "";
  californiaSDeclaration?: string = "";
  mtbf?: number | null = null;
  distributor?: string = "";
  quantity?: string = "";
  lastUpdated?: string = "";
  buyNowLink?: string = "";
  htsusa?: string = "";
  numberOfDistributors: string = "";
  totalMarketInv?: string = "";
}

/** SVHC =  Substance of Very High Concern */
export class SVHCItem {
  [key: string]: any;
  svhcListVersion?: string = "";
  substanceIdentification?: string = "";
  casNumber?: string = "";
  concentration?: string = "";
  thresholdLimitExceeded?: boolean = false;
}

export class Manufacturer extends ManufacturerData {
  [key: string]: any;
  _id?: string = "";
  pcnIssueDate?: string = "";
  pcnDescription?: string = "";
  pcnTypeOfChange?: string = "";
  pcnID?: string = "";
  pcnSource?: string = "";
  pcnEffectiveDate?: string = "";
  // type: string = "";
}
