import { Inject, Injectable } from "@angular/core";
import {
  FranchisedInventoryData,
  FranchisedInventoryDto,
  Manufacturer,
  PcnData,
  PCNDto,
  SeDetail,
} from "../../../../shared/models/manufacturer";
import {
  doc2Model,
  getSEDataById,
  getSeSubstitutes,
  getFranchisedInvDataById,
} from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { formatDate } from "../utils/date.util";
import { ManufacturerUtilsServiceType } from "./manufacturer-utils.service.type";
import * as XLSX from "xlsx";
import { ManufacturerService } from "./manufacturer.service";
import { BehaviorSubject } from "rxjs";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class ManufacturerUtilsService implements ManufacturerUtilsServiceType {
  currentDetails = {} as SeDetail;
  pcnHistoryAsInSE: PCNDto[] = [];
  pcnHistoryDocs: PcnData[] = [];
  pcnHistoryLoading: boolean = false;
  substitutes: Partial<Manufacturer>[] = [];
  cleanSubstitutes: Partial<Manufacturer>[] = [];
  type: "pcnHistory" | "replacements" = "replacements";
  exportDocs: any[] = [];
  exportName: string = "";
  resultExists: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pcnIds: string[] = [];
  cleanHistoryDocs: PcnData[] = [];
  cleanInvDocs: FranchisedInventoryData[] = [];
  currentInvDetails: FranchisedInventoryDto[] = [];
  currentInvDocs: FranchisedInventoryData[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getCurrentFranchisedInvDetails() {
    let seId = this.app.manufacturer.currentManufacturer["manufacturer.seId"];
    if (StringUtils.isNullOrEmpty(seId)) {
      return;
    }

    let franchisedInvData = await getFranchisedInvDataById(
      this.app.customers.expectCurrent,
      this.app.manufacturer.currentManufacturer["manufacturer.seId"]
    );
    this.currentInvDetails = franchisedInvData;
    this.prepareFranchisedInvDocs();
  }

  async getCurrentDetails() {
    let seId = this.app.manufacturer.currentManufacturer["manufacturer.seId"];
    if (StringUtils.isNullOrEmpty(seId)) {
      this.app.manufacturerUtils.pcnHistoryDocs = [];
      return;
    }
    this.currentDetails = await getSEDataById(
      this.app.customers.expectCurrent,
      this.app.manufacturer.currentManufacturer["manufacturer.seId"]
    );
    if (this.currentDetails && this.currentDetails.PCNDetails) {
      if (
        this.currentDetails.PCNDetails.PCNDto &&
        !this.currentDetails.PCNDetails.PCNDto.length
      ) {
        this.pcnHistoryAsInSE.push(
          this.currentDetails.PCNDetails.PCNDto as any
        );
      } else {
        this.pcnHistoryAsInSE = [];
        this.pcnHistoryAsInSE = this.currentDetails.PCNDetails.PCNDto;
      }
      this.preparePcnHistoryDocs();
    }
  }

  prepareFranchisedInvDocs() {
    /** map the fields from the incoming SE document to a property of type FranchisedInventoryData
     * prepare the list to be displayed in the table
     */
    if (this.currentInvDetails) {
      this.currentInvDocs = [];

      this.currentInvDetails.forEach((doc: FranchisedInventoryDto) => {
        let invData = new FranchisedInventoryData();
        if (doc == null) {
          return;
        } else {
          Object.keys(invData).forEach((key: string) => {
            let value = (doc as any)[this.setCorrespondingSeField(key)];
            if (value != null) {
              (invData as any)[key] = value;
            }
          });
        }
        this.currentInvDocs.push(invData);
      });
    }
    this.cleanInvDocs = this.currentInvDocs;
  }

  preparePcnHistoryDocs() {
    /** map the fields from the incoming SE document to a property of type PcnData
     * prepare the list to be displayed in the modal component
     */
    if (this.pcnHistoryAsInSE) {
      this.pcnHistoryDocs = [];

      this.pcnHistoryAsInSE.forEach((doc: PCNDto) => {
        let pcnHistory = new PcnData();
        if (doc == null) {
          return;
        } else {
          Object.keys(pcnHistory).forEach((key: string) => {
            let value = (doc as any)[this.setCorrespondingSeField(key)];
            if (value != null) {
              (pcnHistory as any)[key] = value;
            }
            if (key === "pcnEffectiveDate" || key === "pcnIssueDate") {
              (pcnHistory as any)[key] = formatDate(value);
            }
            if (key === "pcnTypeOfChange") {
              (pcnHistory as any)[key] = this.app.alerts.setTypeOfChange(value);
            }
          });
          pcnHistory._id = this.app.createId();
        }
        this.pcnHistoryDocs.push(pcnHistory);
      });

      this.cleanHistoryDocs = this.pcnHistoryDocs;

      this.pcnHistoryDocs = this.sortHistoryDocs();
      this.pcnIds = this.pcnHistoryDocs.map((doc: PcnData) => doc._id);
    }
  }

  /**sort history docs based on the issueDate */
  sortHistoryDocs() {
    this.pcnHistoryDocs.sort((a: PcnData, b: PcnData) => {
      let aValue =
        a["pcnIssueDate"] !== undefined ? new Date(a["pcnIssueDate"]) : "";
      let bValue =
        b["pcnIssueDate"] !== undefined ? new Date(b["pcnIssueDate"]) : "";
      if (bValue > aValue) {
        return 1;
      }

      if (bValue == aValue) {
        return 0;
      }

      return -1;
    });
    return this.pcnHistoryDocs;
  }

  setCorrespondingSeField(field: string) {
    /** select corresponding field from the SE document */
    switch (field) {
      case "pcnIssueDate":
        return "NotificationDate";
      case "pcnDescription":
        return "DescriptionOfChange";
      case "pcnTypeOfChange":
        return "TypeOfChange";
      case "pcnID":
        return "PCNNumber";
      case "pcnSource":
        return "PcnSource";
      case "pcnEffectiveDate":
        return "EffectiveDate";
      case "distributor":
        return "Distributor";
      case "quantity":
        return "Quantity";
      case "lastUpdated":
        return "LastUpdated";
      case "buyNowLink":
        return "BuyNowLink";
      default:
        return field;
    }
  }

  async getSubstitutes(seId: string) {
    this.substitutes = [];
    this.cleanSubstitutes = [];
    // this.app.spinner.showSpinner();
    const data: any = await getSeSubstitutes(
      this.app.customers.expectCurrent,
      seId
    );
    // this.app.spinner.hideSpinner();
    const { substitutes } = data;
    if (substitutes.length === 0) {
      this.resultExists.next(false);
    } else {
      this.resultExists.next(true);
    }
    for (let substitute of substitutes) {
      //TODO: in se.ts transform substitute.lifeCycleRisk -> substitute.obsolescenceStatus
      substitute.obsolescenceStatus = ManufacturerService.parseSeStatus(
        substitute.lifeCycleRisk
      );
      substitute.euRoHSStatus = ManufacturerService.parseRoHS(
        substitute.euRoHSStatus
      );
    }

    this.substitutes = substitutes;
    this.cleanSubstitutes = substitutes;
  }

  exportTables() {
    const book = this.getBook();
    XLSX.writeFile(book, this.exportName);
  }

  private getBook() {
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, this.getSheet());
    return book;
  }
  private getSheet() {
    const fields = this.getListOfFieldsForExport(this.type);
    return XLSX.utils.json_to_sheet(
      [
        fields.map((field) => this.app.field.getLabel(field)),
        ...this.exportDocs.map((id) =>
          fields.map((field) => this.getValues(field, id))
        ),
      ],
      {
        skipHeader: true,
      }
    );
  }

  getListOfFieldsForExport(type: string) {
    this.exportName = "";
    switch (type) {
      case "replacements":
        this.exportDocs = this.substitutes;
        this.exportName = "config.xlsx";
        return this.app.list.manufacturer.loadReplacements;
      case "pcnHistory":
        this.exportDocs = this.pcnHistoryDocs;
        let mpn =
          this.app.manufacturer.currentManufacturer[
            "manufacturer.manufacturerPartNumberClean"
          ] !== ""
            ? this.app.manufacturer.currentManufacturer[
                "manufacturer.manufacturerPartNumberClean"
              ]
            : this.app.manufacturer.currentManufacturer[
                "manufacturer.maufacturerPartNumberRaw"
              ];
        this.exportName = `${mpn}.xlsx`;
        return this.app.list.manufacturer.pcnHistoryColumns;
      default:
        return this.app.list.manufacturer.pcnHistoryColumns;
    }
  }
  private getValues(fieldId: string, id: Partial<Manufacturer>) {
    /** get the values as they are displayed in the view in order to have the same result in the export */
    switch (fieldId) {
      case "manufacturer.obsolescenceStatus":
        return id["lifeCycleRisk"] != null
          ? this.app.field.getOptionText(fieldId, id["lifeCycleRisk"])
          : "";

      case "manufacturer.pcnEffectiveDate":
      case "manufacturer.pcnIssueDate":
        let date = formatDate(id[fieldId.split(".")[1]]);
        return date;

      case "manufacturer.euRoHS":
        return id["euRoHSStatus"] != null
          ? this.app.field.getOptionText(fieldId, id["euRoHSStatus"])
          : "";
      case "manufacturer.typeOfChange":
        return this.app.field.getFieldValueAsText(fieldId, id[fieldId]);
      case "manufacturer.pcnTypeOfChange":
        let values = id[fieldId.split(".")[1]];
        let result = this.app.field.getFieldValueAsText(fieldId, values);
        return result;
      default:
        return this.app.field.getFieldValueAsText(
          fieldId,
          id[fieldId.split(".")[1]]
        );
    }
  }

  setFieldsFromSelected() {
    let model = doc2Model("manufacturer", {} as Manufacturer);
    Object.keys(this.app.manufacturer.selectedManufacturer).forEach(
      (key: string) => {
        model["manufacturer." + key] =
          this.app.manufacturer.selectedManufacturer[key];
      }
    );
    model["manufacturer.name"] =
      this.app.manufacturer.selectedManufacturer["name"];
    model["manufacturer.nameRaw"] =
      this.app.manufacturer.selectedManufacturer["name"];
    model["manufacturer.manufacturerPartDescription"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartDescription"];
    model["manufacturer.manufacturerPartDescriptionRaw"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartDescription"];
    model["manufacturer.manufacturerPartNumber"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartNumber"];
    model["manufacturer.manufacturerPartNumberRaw"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartNumber"];
    return model;
  }
}
