import { TreeServiceType } from "./tree.service.type";
import { APP_TYPE, AppType } from "../app.type";
import { PartNode, Row } from "./tree.util";
import { Inject, Injectable } from "@angular/core";
import { Part } from "../../../../shared/models/part";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import {
  getManufacturersByPartNumber,
  getPartsCompressedForTree,
  getTopLevelItems,
  triggerWork,
} from "../api.service";

@Injectable()
export class TreeService implements TreeServiceType {
  items: Row[] = [];
  assemblies: string[] = [];
  openParts: Set<string> = new Set();
  manufacturersWithoutParts: Manufacturer[] = [];
  loading = true;
  loadingTreeRows = false;
  pages: string = "";
  partsOfCurrentAssembly: string[] = [];
  partsOfSearchedAssembly: string[] = [];
  currentColumns: string[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async generateTree() {
    this.app.spinner.showSpinner();
    await triggerWork(this.app.customers.expectCurrent, "generate-tree");
    this.app.spinner.hideSpinner();
  }

  async getItems(page?: string) {
    this.app.spinner.showSpinner();

    if (page == null) {
      page = "1";
    }
    if (this.app.paginator.pageSize == null) {
      this.app.paginator.pageSize = 25;
    }
    const { items, assemblies, size } = await getTopLevelItems(
      this.app.customers.expectCurrent,
      page,
      this.app.paginator.pageSize.toString()
    );
    this.items = items;
    this.assemblies = assemblies;
    this.pages = size.toString();

    this.openParts.clear();

    this.loading = false;

    this.app.spinner.hideSpinner();
    return this.items;
  }

  getTreeColumns(): string[] {
    switch (this.app.RMSubmenu.section) {
      case "status":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.statusColumns,
        ];
      case "risk":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.riskColumns,
        ];
      case "likelihood":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.likelihoodColumns,
        ];
      case "impact":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.impactColumns,
        ];
      case "rohs":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.rohsColumns,
        ];
      case "reach":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.reachColumns,
        ];
      case "lead":
        return [
          ...this.app.list.RM.commonColumns,
          ...this.app.list.RM.leadColumns,
        ];
    }
  }

  async toggleTreeRow(row: Row) {
    // this.app.spinner.showSpinner();
    this.loadingTreeRows = true;

    if (!this.app.tree.isPartRow(row)) {
      return;
    }
    if (row.node == null || row.node.part == null) {
      return;
    }
    if (!this.openParts.has(row.node.part.partNumber)) {
      this.openParts.add(row.node.part.partNumber);
      await this.getRows(row);
    } else {
      this.openParts.delete(row.node.part.partNumber);
      this.removeFromItemList(row);
    }

    this.app.treeRow.isOpenRow(row);
    this.app.treeRow.isLeafRow(row);

    // this.app.spinner.hideSpinner();
    this.loadingTreeRows = false;
  }

  removeFromItemList(row: Row) {
    let result = this.findChildrenOfRow(row);
    const { index, childrenCount, childrenOpen } = result;
    /** remove the items(its children) found on the next level of the item clicked  */
    this.items.splice(index + 1, childrenCount);
    // Remove from openParts all children open from the current closed row
    this.removeChildrenFromOpenParts(childrenOpen);
  }

  /** if the item has been shown already, it has to be removed from the list of items */
  findChildrenOfRow(row: Row) {
    /** get the index of the current row */
    let index = this.items.indexOf(row);
    let childrenCount = 0;
    let childrenOpen = [];

    /** find the index until where the level is bigger than the level it was clicked on */
    for (let i = index + 1; i < this.items.length; i++) {
      let currentLevel = this.items[i].level;
      /** once the level is equal to the one that it was clicked then return */
      if (currentLevel === row.level) {
        break;
      }

      childrenOpen.push(this.items[i]);

      if (currentLevel && currentLevel > row.level) {
        childrenCount++;
      }
    }
    return { index, childrenCount, childrenOpen };
  }

  removeChildrenFromOpenParts(rows: Row[]) {
    let children: string[] = [];

    rows.forEach((row: Row) => {
      if (row.node == null || row.node.part == null) {
        return;
      }
      children.push(row.node.part._id);
    });

    this.openParts.forEach((part) => {
      if (children.includes(part)) {
        this.openParts.delete(part);
      }
    });
  }

  isOpenRow(row: Row) {
    if (row.node == null || row.node.part == null) {
      return false;
    }
    return this.openParts.has(row.node.part.partNumber);
  }

  isLeafRow(row: Row): boolean | null {
    const { node } = row;
    if (node == null) {
      return null;
    }
    if (node.part.parts != null) {
      let parts = Object.keys(node.part.parts);
      if (parts != null && parts.length > 0) {
        return false;
      }
    }
    return false;
  }

  async getRows(row: Row) {
    let childRows: Row[] = [];

    if (row.node == null || row.node.part == null) {
      return;
    }

    // If the current row does not contain any parts then it should contain only manufacturers
    if (
      row.node.part.parts == null ||
      Object.keys(row.node.part.parts).length === 0
    ) {
      // this.app.spinner.showSpinner();
      childRows = await this.app.manufacturer.getManufacturerRows(row);
      // this.app.spinner.hideSpinner();
    } else {
      // If the current row is still an assembly, get its children(parts + manufacturer) and add it to the list
      // this.app.spinner.showSpinner();
      childRows = await this.getChildrenRows(row);
      // this.app.spinner.hideSpinner();
    }
    let index = this.items.findIndex((r) => r === row);
    if (index !== -1) {
      this.items.splice(++index, 0, ...childRows);
    }
  }

  private async getChildrenRows(row: Row) {
    if (row.node == null || row.node.part == null) {
      return [];
    }
    let manufacturers: Manufacturer[] = [];
    let parts: Part[] = [];
    let childrenRows: Row[] = [];

    // Get all manufacturers that are children to the current row
    manufacturers = await getManufacturersByPartNumber(
      this.app.customers.expectCurrent,
      row.node.part.partNumber
    );

    // Get all parts that are children to the current row
    // If there is at least a filter selected the list of containing children has to be taken from the se-filter request directly
    if (
      row.level !== 0 &&
      Object.keys(this.app.filterTree.filterValues).length > 0
    ) {
      parts = this.getPartRowsFromSearch(row);
    } else {
      parts = await getPartsCompressedForTree(
        this.app.customers.expectCurrent,
        Object.keys(row.node.part.parts)
      );
    }

    // Convert manufacturers to tree rows
    manufacturers.forEach((child) => {
      let childRow: Row = {} as Row;
      childRow.level = row.level != null ? row.level + 1 : 1;
      childRow.manufacturer = child;
      childrenRows.push(childRow);
    });

    // Convert parts to tree rows
    parts.forEach((child) => {
      let node: PartNode = {} as PartNode;
      node.bom = [];
      node.part = child;

      let childRow: Row = {} as Row;
      childRow.node = node;
      childRow.level = row.level != null ? row.level + 1 : 1;

      if (row.node != null && row.node.part != null) {
        childRow.quantity = row.node.part.parts[child._id];
      }
      childrenRows.push(childRow);
    });
    return childrenRows;
  }

  getPartRowsFromSearch(row: Row) {
    if (row.node == null || row.node.part == null) {
      return [];
    }
    /** list of the part.parts from the current row before the search is performed */
    let currentParts = Object.keys(row.node.part.parts);

    /** list of parts resulted from search */
    let resultedParts = this.app.filterTree.result.parts;

    /** list of common parts from both lists */
    let intersection: string[] = [];

    let result: Part[] = [];

    intersection = currentParts.filter((v) =>
      resultedParts.map((p: Partial<Part>) => p._id).includes(v)
    );
    /** get the common parts from the both list and used that for displaying the result */
    if (intersection.length > 0) {
      intersection.forEach((i) => {
        let index = resultedParts.findIndex(
          (part: Partial<Part>) => part._id === i
        );
        if (index !== -1) {
          result.push(resultedParts[index]);
        }
      });
    }
    return result;
  }

  getHeaderStyle(column: string) {
    switch (column) {
      case this.app.fieldId.part.partNumber:
        return "medium-col";
      case this.app.fieldId.part.partsToVehicles:
      case this.app.fieldId.part.quantity:
        // case this.app.fieldId.part.detailView:
        return "small-col";
      case this.app.fieldId.manufacturer.purchasePartNumber:
      case this.app.fieldId.part.description:
      case this.app.fieldId.part.itemType:
      case this.app.fieldId.part.sapStatus:
        return "large-col";
      case this.app.fieldId.part.threadCase:
      case this.app.fieldId.part.matchedSystem:
      case this.app.fieldId.part.obsolescenceManagement:
        return "icon-column";
      default:
        return "";
    }
  }

  isPartRow(row: Row | null | undefined) {
    return row != null && row.node != null && row.node.part != null;
  }
  isManufacturerRow(row: Row | null | undefined) {
    return row != null && row.manufacturer != null && row.node == null;
  }

  /** moved to operations manager */
  // getManufacturersWithoutParts(parts: Part[], manufacturers: Manufacturer[]) {
  //   const { manufacturersWithoutNode } = getTree(parts, manufacturers);
  //   this.manufacturersWithoutParts = manufacturersWithoutNode;
  // }
}
