import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { saveAs } from "file-saver-es";
import { WorkHistory } from "../../../../shared/models/workHistory";
import {
  downloadFile,
  getParameter,
  saveParameters,
  updateTasksForEditedResponsibles,
} from "../api.service";
import { getApp } from "../app";
import { WorkCount } from "./operations.service";
import { Parameter } from "../../../../shared/models/parameter";
import { Customer } from "../../../../shared/types/customers";
@Component({
  selector: "app-operations",
  templateUrl: "./operations.component.html",
  styleUrls: ["./operations.component.scss"],
})
export class OperationsComponent implements OnInit, OnDestroy {
  @Input() showPaginator = true;
  public history: WorkHistory[] = [];
  public count: WorkCount | null = null;
  public workType:
    | "all"
    | "match"
    | "request-details"
    | "update-details"
    | "delete-docs"
    | "generate-tree"
    | "update-impacts"
    | "generate-alerts"
    | "build-bom" = "match";
  public loading = false;

  parameterModel: Parameter = new Parameter();
  standardRangeValue: number = 0;

  app = getApp(async (app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit(): Promise<void> {
    await this.getHistory();
    await this.getWorkCount();

    this.parameterModel = await getParameter();
    this.standardRangeValue = this.parameterModel.standardRange;

    // TODO: find a better condition to set as default workType "generate-alerts" for team role
    if (
      this.app.customers.expectCurrent === Customer.MTU &&
      this.app.auth.isTeam
    ) {
      this.workType = "generate-alerts";
    }
  }

  async getHistory(): Promise<void> {
    this.history = await this.app.operations.getWorkHistory();
  }

  async getWorkCount(): Promise<void> {
    this.count = await this.app.operations.getWorkCount();
  }

  async downloadLog(item: WorkHistory) {
    const fileLink = [
      this.app.customers.expectCurrent,
      "workHistory",
      btoa(item._id),
      "log.txt",
    ].join("/");
    const data = await downloadFile(fileLink);
    const blob = new Blob([data], { type: data.type });

    saveAs(blob, "log.txt");
  }

  async runWork(): Promise<void> {
    this.loading = true;
    await this.app.operations.triggerWork(this.workType);
    this.loading = false;
    setTimeout(async () => {
      await this.getHistory();
    }, 5000);
  }

  async runUpdateTaskResponsibles() {
    this.app.spinner.showSpinner();
    await updateTasksForEditedResponsibles(this.app.customers.expectCurrent);
    this.app.spinner.hideSpinner();
  }

  async saveStandardRange() {
    const { parameterModel } = this;
    parameterModel.type = "parameter";
    await saveParameters(parameterModel);
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.import.documentsSavedSuccesfully,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 1000);
  }

  isDisabled(hasStandardRangeCheck: boolean) {
    if (this.workType === "generate-alerts") {
      const { parameterModel } = this;
      let standardRange = parameterModel["standardRange"];

      if (standardRange <= 0 || standardRange === undefined) {
        return true;
      }

      if (hasStandardRangeCheck && standardRange === this.standardRangeValue) {
        return true;
      }

      return false;
    }
  }

  ngOnDestroy(): void {}
}
