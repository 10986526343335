<div class="col-md-11 descriptionList mt-2">
  <div>
    <div class="d-flex">
      <div class="field-label">
        {{
          app.field.getLabel(
            app.fieldId.manufacturer.manufacturerPartNumberClean
          )
        }}:&nbsp;
      </div>

      <div class="lineHeight">
        {{ app.manufacturer.selectedManufacturer["PartNumber"] }}
      </div>
    </div>
    <div class="d-flex">
      <div class="field-label">
        {{
          app.field.getLabel(
            app.fieldId.manufacturer.manufacturerPartDescription
          )
        }}:&nbsp;
      </div>
      <div class="lineHeight">
        {{ app.manufacturer.selectedManufacturer["Description"] }}
      </div>
    </div>
    <div class="d-flex">
      <div class="field-label">
        {{ app.text.manufacturer.assignedCPN }}&nbsp;
      </div>
      <div class="lineHeight">
        <span *ngFor="let cpn of assignedCPNs; let i = index">
          {{ cpn }} {{ i === assignedCPNs.length - 1 ? "" : ",&nbsp;" }}
        </span>
      </div>
    </div>
  </div>
</div>
