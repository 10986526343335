import { Inject, Injectable } from "@angular/core";
import {
  Manufacturer,
  Multi,
  SeMatch,
  SeMatchingResult,
} from "../../../../shared/models/manufacturer";
import { doc2Model, getManufacturersCompressed } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { ManufacturerService } from "../manufacturer/manufacturer.service";
import { SeFilterServiceType } from "./se-filter.service.type";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SeFilterService implements SeFilterServiceType {
  currentMatch: SeMatch[] = [];
  selectedMatch = new SeMatch();
  matchesCopy: SeMatch[] = [];
  matches: SeMatch[] = [];
  searchedPartNumber: string = "";
  isAdded: boolean = false;
  manufacturers: Manufacturer[] = [];
  redoSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getSeSearchResults(results: SeMatchingResult[]) {
    this.app.manufacturerMatching.manufacturer.matches = [];
    this.matches = [];
    let manufacturer = this.app.manufacturerMatching.manufacturer;
    results.forEach((result) => {
      this.asArray(result.PartList).forEach(({ PartDto }) => {
        this.asArray(PartDto).forEach((match) => {
          if (!manufacturer.matches) {
            manufacturer.matches = [];
          }
          match.Lifecycle = ManufacturerService.parseSeStatus(match.Lifecycle);
          match.RoHS = ManufacturerService.parseRoHS(match.RoHS);
          manufacturer.matches.push(match);
          manufacturer.searchResults = true;
          this.matches = manufacturer.matches;
          this.matchesCopy = manufacturer.matches;
        });
      });
    });
  }

  asArray<T>(multi: Multi<T>) {
    if (multi == null) {
      return [];
    }
    if (Array.isArray(multi)) {
      return <T[]>multi;
    }
    return [<T>multi];
  }

  newCase(doc: SeMatch, searchedCpn: string) {
    this.selectedMatch = doc;
    this.selectedMatch.type = "seMatch";
    sessionStorage.setItem("se_filter_cpn", searchedCpn);
    //clear all variables that could have fields info about a thread
    this.app.pcn.pcn = null;
    this.app.pcn.id = null;
    this.app.routing.navigateNewThread();
  }

  setSeFields() {
    let model = doc2Model("manufacturer", {} as Manufacturer);
    let selectedManufacturer = new SeMatch();
    Object.keys(this.app.manufacturer.selectedManufacturer).forEach((key) => {
      selectedManufacturer[key] =
        this.app.manufacturer.selectedManufacturer[key];
    });
    model["manufacturer.name"] = selectedManufacturer.Manufacturer;
    model["manufacturer.nameRaw"] = selectedManufacturer.Manufacturer;
    model["manufacturer.manufacturerPartNumber"] =
      selectedManufacturer.PartNumber;
    model["manufacturer.manufacturerPartNumberRaw"] =
      selectedManufacturer.PartNumber;
    model["manufacturer.manufacturerPartDescription"] =
      selectedManufacturer.Description;
    model["manufacturer.manufacturerPartDescriptionRaw"] =
      selectedManufacturer.Description;
    model["manufacturer.seId"] = selectedManufacturer.ComID;
    model["manufacturer.obsolescenceStatus"] = selectedManufacturer.Lifecycle;
    model["manufacturer.datasheet"] = selectedManufacturer.Datasheet;
    model["manufacturer.euRoHS"] = selectedManufacturer.RoHS;
    if (selectedManufacturer.YEOL != "Unknown") {
      model["manufacturer.estimatedYearsToEOL"] = Number(
        selectedManufacturer.YEOL
      );
    }
    model["manufacturer.partNumber"] =
      this.app.model["manufacturer.partNumber"];
    return model;
  }
}
