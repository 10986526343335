import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { getApp } from "../app";
import { SearchWords } from "../filter/filter.service.type";
import { FieldTypes } from "../../../../shared/settings/settings";

@Component({
  selector: "app-filter-header-table",
  templateUrl: "./filter-header-table.component.html",
  styleUrls: ["./filter-header-table.component.scss"],
})
export class FilterHeaderTableComponent implements OnInit {
  @Input() column: string = "";
  @Input() docType: string = "";
  fieldId: string = "";
  fieldType: string = "";
  model: SearchWords = {};

  app = getApp((app) => {
    this.app = app;
  });

  private _resetFilterSubscription: Subscription = new Subscription();
  private _sectionSubscription: Subscription = new Subscription();

  async ngOnInit() {
    this.fieldId = this.app.filterHeaderTable.getFieldId(
      this.docType,
      this.column
    );
    this.fieldType = this.setFieldType();

    this._resetFilterSubscription =
      this.app.filterHeaderTable.resetAllFilters.subscribe((value) => {
        if (value) {
          this.model[this.fieldId] = "";
          this.app.filterHeaderTable.resetHeaderFilters();
        }
      });

    this._sectionSubscription = this.app.RMSubmenu.sectionSubject.subscribe(
      async () => {
        // await this.app.filterHeaderTable.getOptionsBasedOnProperty(
        //   this.fieldId
        // );

        if (this.app.filterHeaderTable.isFilterSelected) {
          this.app.paginator.resetPagination();
        }
      }
    );
  }

  setFieldType() {
    // Make the fields of type typeahead

    this.app.field.changeFieldSettings(this.fieldId, FieldTypes.typeahead);
    return this.app.filterHeaderTable.getFieldType(this.fieldId);
  }

  checkKey(event: KeyboardEvent, field: any, model: any) {
    if (event.key === "Enter") {
      this.app.filterHeaderTable.filter(field, model);
    }
    if (event.key === "Backspace") {
      if (model != null && model.length === 1) {
        this.app.filterHeaderTable.clearFilter(field);
      }
    }
  }

  clearFilter(fieldId: string) {
    this.app.filterHeaderTable.clearFilter(fieldId);
    this.model[fieldId] = "";
  }

  ngOnDestroy(): void {
    if (this._resetFilterSubscription) {
      this._resetFilterSubscription.unsubscribe();
    }
    if (this._sectionSubscription) {
      this._sectionSubscription.unsubscribe();
    }
    this.app.field.resetFieldType(this.fieldId);
  }
}
