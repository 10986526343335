import { APP_TYPE, AppType } from "../app.type";
import { bulkDocsByType, deleteAllImpacts, getImpacts } from "../api.service";
import { ImpactsServiceType } from "./impacts.service.type";
import { Inject, Injectable } from "@angular/core";
import { Impact } from "../../../../shared/models/impact";
import {
  SPECIAL_CONFIGURATION,
  USUAL_CONFIGURATION,
} from "../customers/customers.service";
import { BehaviorSubject } from "rxjs";
import { Responsibles } from "../../../../shared/models/responsibles";
import { VehicleResponsible } from "../../../../shared/models/vehicleResponsible";
import { Customer } from "../../../../shared/types/customers";
import { JOBTITLE_KM } from "../users/users.service.type";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class ImpactsService implements ImpactsServiceType {
  currentImpact: Impact = {} as Impact;
  impactsCompressed: any[] = [];
  cleanVehicles: any[] = [];

  newImpactsAdded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  impacts: Impact[] = [];

  partsToVehiclesSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  impactIds: string[] = [];
  cleanImpacts: Responsibles[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get partsToVehicles() {
    return this.app.state.partsToVehicles;
  }

  set partsToVehicles(array: any[]) {
    this.app.state.partsToVehicles = [];
    this.partsToVehiclesSubject.next(true);
  }

  async getImpacts(omfNumber?: string) {
    this.impacts = await getImpacts(
      this.app.customers.expectCurrent,
      omfNumber
    );
    this.cleanImpacts = [];
    this.impacts.forEach((impact: Impact) => {
      if (impact.impacts == null) {
        impact.impacts = [];
      }

      if (this.app.customers.expectCurrent === Customer.DB) {
        /** created an object with all the properties needed for the table filtering */
        if (impact.responsibles != null) {
          let impactResponsibles = impact.responsibles;
          impactResponsibles._id = impact._id;
          impactResponsibles.omfVehicleName = impact.omfVehicleName;
          impactResponsibles.omfVehicleFleet = impact.omfVehicleFleet;
          this.cleanImpacts.push(impactResponsibles);
        }
      }
    });
    this.impactIds = this.impacts.map((impact: Impact) => impact._id);
    return this.impacts;
  }

  createImpactsArray(impacts: any): string[] {
    if (this.app.list.impact.fields.includes("impact.artNumber")) {
      if (this.app.customers.expectCurrent === Customer.KNDS) {
        impacts = impacts
          .filter((impact: Impact) => impact.artNumber !== null)
          .map((impact: Impact) => {
            if (StringUtils.isNullOrEmpty(impact.omfVehicleName)) {
              return `${impact.artNumber} (${impact.projectNumber})`;
            } else {
              return `${impact.artNumber} (${impact.omfVehicleName} / ${impact.projectNumber})`;
            }
          });
      } else {
        impacts = impacts
          .filter((impact: Impact) => impact.artNumber !== null)
          .map((impact: Impact) => {
            if (StringUtils.isNullOrEmpty(impact.omfVehicleName)) {
              return impact.artNumber;
            } else {
              return `${impact.artNumber} (${impact.omfVehicleName})`;
            }
          });
      }
      return impacts;
    } else {
      return impacts
        .filter((impact: Impact) => impact.omfVehicleName !== null)
        .map((impact: Impact) => impact.omfVehicleName);
    }
  }

  async checkIfImpactExists() {
    let name: string = "";

    let property = "";

    /** search has to be done by artNumber if the client is using RM */
    if (
      this.app.customers.hasRM() &&
      this.app.customers.getSpecialCustomers() === USUAL_CONFIGURATION
    ) {
      property = "artNumber";
    }

    /** search has to be done by omfVehicleName if the client has a special-configuration */
    if (
      !this.app.customers.hasRM() ||
      this.app.customers.getSpecialCustomers() === SPECIAL_CONFIGURATION
    ) {
      property = "omfVehicleName";
    }
    name = this.app.impact.currentImpact["impact." + property];

    let index = this.impacts.findIndex(
      (impact) =>
        impact[property] === name &&
        impact._id !== this.app.impact.currentImpact._id
    );

    if (index !== -1) {
      return true;
    }

    return false;
  }

  async generateImpacts(docs: any[]) {
    this.app.spinner.showSpinner();

    let docsToSave: any[] = [];
    let omfNumber = this.app.thread.thread[this.app.fieldId.thread.omfNumber];

    docs.forEach((doc) => {
      let impact = this.createImpact(doc);
      let existingDoc = this.checkIfExists(doc.omfVehicleName, omfNumber);

      if (existingDoc != null) {
        impact = this.updateFieldsFromImport(impact, existingDoc);
        impact._id = existingDoc._id;
        impact._rev = existingDoc._rev;
      } else {
        delete impact._id;
        delete impact._rev;
      }

      impact.omfNumber = omfNumber;

      if (this.app.customers.expectCurrent === Customer.KNDS) {
        impact.projectResponsible = this.setProjectResponsible(impact);
      }

      docsToSave.push(impact);
    });

    await bulkDocsByType(
      this.app.customers.expectCurrent,
      "impact",
      docsToSave
    );
    this.newImpactsAdded.next(true);
    this.app.spinner.hideSpinner();
  }

  setProjectResponsible(impact: any) {
    if (this.app.state.omfNumber == null) {
      return;
    }
    let project = impact.projectNumber;

    this.app.users.userRoles.forEach((user: any) => {
      if (user.projectNumber === undefined) {
        return;
      }

      if (user.projectNumber === project && user.jobTitle === JOBTITLE_KM) {
        impact.projectResponsible = user.name;
      }
    });

    return impact.projectResponsible;
  }

  async generateImpactsFromVehicles(docs: any[]) {
    let docsToSave: any[] = [];
    let omfNumber = this.app.thread.thread[this.app.fieldId.thread.omfNumber];
    let vehicleNames =
      this.app.thread.thread[this.app.fieldId.thread.vehicleNames];
    docs.forEach((doc) => {
      let impact = this.createImpact(doc);
      let existingDoc = this.checkIfExists(doc.omfVehicleName, omfNumber);
      if (existingDoc != null) {
        return;
      }
      /** find match and return the document with the new values */
      let match = this.matchVResponsible(impact);
      match.type = "impact";
      match.omfNumber = omfNumber;
      docsToSave.push(match);
    });
    this.app.spinner.showSpinner();
    await bulkDocsByType(
      this.app.customers.expectCurrent,
      "impact",
      docsToSave
    );
    this.app.spinner.hideSpinner();
    this.newImpactsAdded.next(true);

    /** update the list of vehicleNames from case */
    let vNames = docsToSave.map((i: Impact) => i.omfVehicleName);
    let allNames = [...vNames, ...vehicleNames];
    this.app.thread.thread[this.app.fieldId.thread.vehicleNames] = allNames;
    await this.app.thread.save(this.app.thread.thread);
  }

  createImpact(doc: any) {
    let impact = new Impact();
    let responsibles = new Responsibles();

    Object.keys(doc).forEach((key) => {
      if (Object.keys(impact).findIndex((field) => field === key) !== -1) {
        impact[key] = doc[key];
      }
      if (Object.keys(responsibles).findIndex((k) => k === key) !== -1) {
        responsibles[key] = doc[key];
      }
      /** create a copy of the "responsibles" object and assign only the not empty values */

      let responsiblesCopy: Partial<Responsibles> = {};
      Object.keys(responsibles).forEach((field) => {
        if (responsibles[field] !== "") {
          responsiblesCopy[field] = responsibles[field];
        }
      });

      /** set on the impact object the result filtered by the non empty values */
      impact["responsibles"] = responsiblesCopy;
    });
    let impactCopy: Partial<Impact> = {};
    Object.keys(impact).forEach((field) => {
      if (impact[field] !== "") {
        impactCopy[field] = impact[field];
        delete impactCopy._id;
      }
    });
    impactCopy["type"] = "impact";

    return impactCopy;
  }

  private checkIfExists(vehicleName: string, omfNumber: string) {
    if (this.app.impacts.impacts) {
      let impact = this.app.impacts.impacts.find(
        (impact: Partial<Impact>) =>
          impact.omfVehicleName === vehicleName &&
          impact.omfNumber === omfNumber
      );
      return impact;
    }
  }

  private updateFieldsFromImport(doc: Partial<Impact>, existingDoc: Impact) {
    Object.keys(doc).forEach((key) => {
      if (doc[key] !== "") {
        if (
          doc.responsibles != null &&
          existingDoc.responsibles != null &&
          key === "responsibles"
        ) {
          Object.keys(doc.responsibles).forEach((k: string) => {
            if (
              (doc as any).responsibles[k] !==
              (existingDoc as any).responsibles[k]
            ) {
              (existingDoc as any).responsibles[k] = (doc as any).responsibles[
                k
              ];
            }
          });
        } else {
          existingDoc[key] = (doc as any)[key];
        }
      }
    });
    return existingDoc;
  }

  /** if the imported documents are of type impact and client is DB
   * the list of provided vehicleNames should be matched to an existing vehicleResponsible
   * and should take its values from the responsibles
   */
  matchVResponsible(doc: Partial<Impact>) {
    let match = this.app.vehicleResponsible.vehicles.find(
      (v: VehicleResponsible) => v.vehicleName === doc.omfVehicleName
    );
    if (match != null) {
      Object.keys(match).forEach((key) => {
        if (key === "responsibles") {
          doc[key] = [];
          if (match && match[key] != null) {
            doc[key] = match[key];
          }
        }
        if (key === "vehicleFleet") {
          (doc as any)["omfVehicleFleet"] = (match as any)[key];
        }
      });
    }
    return doc;
  }

  async deleteAllImpacts() {
    const customer = this.app.customers.expectCurrent;
    const threadId = this.app.thread.thread["thread._id"];
    const threadOMFNumber = this.app.thread.thread["thread.omfNumber"];

    await deleteAllImpacts(customer, threadId);

    this.app.thread.getThread(threadId);
    this.app.impacts.getImpacts(threadOMFNumber);
    this.app.post.getThreadPosts(customer, threadOMFNumber);
  }
}
