import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Definition } from "../pdf/definition";
import { Impact } from "../../../../shared/models/impact";
import { PdfExport } from "../pdf/export";
import { Subscription } from "rxjs";
import { Thread } from "../../../../shared/models/thread";
import { StringUtils } from "../../../../shared/utils/string.utils";
import {
  alertMapping,
  manufacturerMapping,
  partMapping,
  seMatchMapping,
} from "../../../../shared/rm-mapping";
import { Customer } from "../../../../shared/types/customers";
import { getManufacturerByMPN } from "../api.service";

@Component({
  selector: "app-thread",
  templateUrl: "./thread.component.html",
  styleUrls: ["./thread.component.scss"],
})
export class ThreadComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput", { static: false }) fileInput: any;
  @ViewChild("noIdModal", { static: false }) noIdModal: any;
  @ViewChild("overrideMpnFieldsModal", { static: true })
  overrideMpnFieldsModal: any;

  app = getApp((app) => {
    this.app = app;
  });
  fromTree: string | null = "";
  boxesLeft: string[] = this.app.list.thread.boxesLeft;
  threadId: string | null = null;
  private _attachmentEdited: Subscription = new Subscription();
  private _routeParamsSubscription: Subscription = new Subscription();
  private _overrideModalSubscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.thread.cleanModel = {} as Thread;
    // Reset container page, posts, externalImage and boxesLeft every time enter in a thread
    // this.app.leftNav.resetContainer();
    this.app.post.resetPosts();
    this.app.thread.externalImage = "";
    // this.app.thread.externalImage = this.app.url.part.defaultImage;

    this.setBoxesLeft();
    // Subscribe to params from the route to get the thread id
    this._routeParamsSubscription = this.route.params.subscribe(
      async (param) => {
        if (param.threadId !== undefined) {
          this.threadId = param.threadId;
        }
      }
    );

    /** Subscribe to attachmentsInfo if the attachment is deleted from the attachments section
     *  the case image has to be updated
     */
    this._attachmentEdited = this.app.file.attachmentsInfo.subscribe(
      (value: any) => {
        if (
          value &&
          this.app.thread.thread["thread._attachments"] &&
          !Object.keys(this.app.thread.thread["thread._attachments"]).includes(
            "Bild.jpg"
          )
        ) {
          this.app.thread.externalImage = "";
        }
        if (
          !StringUtils.isNullOrEmpty(
            this.app.thread.thread["thread.externalImageSource"]
          )
        ) {
          this.app.thread.externalImage =
            this.app.thread.thread["thread.extenalImageSource"];
        }
      }
    );

    // Get the thread details if threadId exists
    if (this.threadId != null) {
      await this.app.thread.getThread(this.threadId);
      this.app.routing.setTabTitle(
        this.app.thread.thread["thread.omfNumber"] + " - LCM Client"
      );
      // Call several function in order to display: thread image, favorite, syncs, comments and permissions
      await this.app.thread.prepareThreadData(this.app.thread.id);
    } else {
      this.app.thread.isNew = true;
      this.app.thread.getImageExternal();
      // Redirect to homepage if user refreshes the create new thread page
      if (sessionStorage.getItem("viewRefresh") != null) {
        this.app.routing.navigateHome();
      }
    }
    // The following functions needs to be called in new case, read/edit mode
    await this.app.thread.getThreadInitFunctions();
    this.mapFields();
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      // this.app.field.manufacturerNames = await getManufacturerNamesList();
      let value: string =
        this.app.thread.thread[this.app.fieldId.thread.creator];
      if (!StringUtils.isNullOrEmpty(value)) {
        value = value.toUpperCase();
        await this.app.field.getManufacturerCodeByName(value);
      }

      this.app.field.getTypeaheadOptionsForCageCode();
    }
    // this.app.manufacturer.getTypeaheadOptions();
    // Reset selected box to the first section of the thread
    if (!this.app.thread.isNew) {
      this.app.leftNav.selectedBox = this.app.listId.thread.main;
    }

    this._overrideModalSubscription = this.app.thread.modalSubject.subscribe(
      (value: string) => {
        if (
          !StringUtils.isNullOrEmpty(value) &&
          this.app.thread.isCreatedFromAlert
        ) {
          this.prepareOverrideMpnFieldsModal(value);
        }
      }
    );

    this.app.thread.threadReady = true;
  }

  async prepareOverrideMpnFieldsModal(modal: string) {
    switch (modal) {
      case "cpnModal":
        this.app.overrideMpnFields.selectedField =
          this.app.fieldId.thread.artNumber;
        if (this.app.alerts.currentSelected.partNumber != null) {
          const part = await this.app.part.getPartByPartNumberWithoutBuffer(
            this.app.alerts.currentSelected.partNumber
          );
          this.app.manufacturer.selectedPart = part;
        }
        break;
      case "mpnModal":
        this.app.overrideMpnFields.selectedField =
          this.app.fieldId.thread.crtNumber;
        if (this.app.alerts.currentSelected.manufacturerPartNumber != null) {
          const manufacturerList = await getManufacturerByMPN(
            this.app.customers.expectCurrent,
            this.app.alerts.currentSelected.manufacturerPartNumber
          );

          // if will return more than one manufacturer, only the first one will be considered
          const manufacturer = manufacturerList[0];
          this.app.manufacturer.selectedManufacturer = manufacturer;
        }
        break;
      default:
        this.app.overrideMpnFields.selectedField =
          this.app.fieldId.thread.artNumber;
        if (this.app.alerts.currentSelected.partNumber != null) {
          const part = await this.app.part.getPartByPartNumberWithoutBuffer(
            this.app.alerts.currentSelected.partNumber
          );
          this.app.manufacturer.selectedPart = part;
        }
        break;
    }
    this.overrideMpnFieldsModal.open();
  }

  //set boxesLeft based on permission
  setBoxesLeft() {
    if (!this.app.permission.attachments.seeAttachments) {
      let index = this.app.list.thread.boxesLeft.findIndex(
        (box) => box === "thread.files"
      );
      if (index !== -1) {
        this.boxesLeft = this.app.list.thread.boxesLeft;
        this.boxesLeft.splice(index, 1);
      }
    }
  }

  async onUpload() {
    const file: File = this.fileInput.nativeElement.files[0];
    if (file.type.indexOf("image/") !== 0) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.thread.uploadImageTypeError;
    } else {
      this.app.hasError = false;
      this.app.errorText = "";
      const fileLink = this.app.thread.getImage();

      //thread profile image should be deleted first, otherwise it will be uploaded as Bild_1.jpg
      // await this.app.file.delete(fileLink);
      if (
        !StringUtils.isNullOrEmpty(
          this.app.thread.thread["thread.externalImageSource"]
        )
      ) {
        this.app.thread.thread["thread.externalImageSource"] = "";
        await this.app.thread.save(this.app.thread.thread);
      }
      await this.app.file.upload(file, fileLink);
      this.app.thread.getImageExternal();
      this.app.thread.hasDeleteImagePermission();
    }
  }

  navigateBack() {
    const length = this.app.routing.navigationHistory.length;
    const lastRoute = this.app.routing.navigationHistory[length - 2];
    if (lastRoute == null) {
      this.app.routing.navigateHome();
      return;
    }

    // after creating a case from manufacturer tables, and navigate back to the table, the paginator need to be reset to the first page
    if (lastRoute[1] != "home" && lastRoute[1] != "mail") {
      this.app.paginator.updatePage.next(true);
    }

    switch (lastRoute[1]) {
      case "mail":
        if (lastRoute[2] === "thread" || lastRoute[2] === "change") {
          const inboxType: any = lastRoute[2];
          this.app.routing.navigateMail(inboxType);
        } else {
          this.app.routing.navigateHome();
        }
        break;
      case "part":
        this.app.routing.navigatePart(lastRoute[2].toString());
        break;
      default:
        this.app.routing.navigateBack();
    }
  }

  async downloadPDF(pdfType?: string) {
    //if the pdfType is "all" the document should contain all data, otherwise it should include only the first page of vdma
    let items = new Set();
    if (pdfType === "allData") {
      items = new Set(this.app.list.thread.pdfExportItems);
    }
    const definition = new Definition(
      {
        pageSize: "A4",
        pageOrientation: "landscape",
      },
      this.app
    );
    definition.addVdmaStandardClientDef(
      Definition.mapClientFields(),
      "omc",
      pdfType
    );
    if (items.has("layer")) {
      definition.addLayerSection(this.app.list.thread.layer);
      this.app.list.thread.layerSections.forEach((section) => {
        definition.addLayerSection(this.app.getList(section), section);
      });
    }
    if (items.has("impact") || items.has("train")) {
      let impacts: Impact[] = [];
      impacts = await this.app.impacts.getImpacts(
        this.app.thread.thread["thread.omfNumber"]
      );

      let usualImpacts = impacts.filter(
        (i) => i.impactType === undefined || i.impactType !== "train"
      );
      definition.addImpactSection(usualImpacts);
      //export in pdf trainseries section, the impacts created when a train is added to a case
      let trainImpacts = impacts.filter((i) => i.impactType === "train");
      definition.addTrainSection(trainImpacts);
    }

    if (items.has("posts")) {
      definition.addPostSection();
    }
    if (items.has("attachments")) {
      definition.addAttachmentsSection();
    }
    if (items.has("signature")) {
      definition.addSignatureFields();
    }
    new PdfExport(definition.get()).open();
  }

  private async mapFields() {
    let currentDoc = this.app.treeRow.currentManufacturer;
    if (sessionStorage.getItem("manufacturer") != null) {
      var doc = JSON.parse(sessionStorage.manufacturer);
    }
    if (doc != null && Object.keys(doc).length > 0) {
      currentDoc = doc;
      this.app.type = "manufacturer";
    }
    if (
      doc &&
      Object.keys(currentDoc).length > 0 &&
      (this.app.type === "part" || this.app.type === "manufacturer")
    ) {
      this.app.thread.copyRmFieldsToThread(currentDoc, manufacturerMapping);

      if (
        this.app.customer === Customer.NS &&
        !StringUtils.isNullOrEmpty(currentDoc.partNumber)
      ) {
        const part = await this.app.part.getPartByPartNumberWithoutBuffer(
          currentDoc.partNumber
        );
        this.app.thread.copyRmFieldsToThread(part, partMapping);
      }
    }
    if (
      Object.keys(this.app.alerts.currentSelected).length > 0 &&
      this.app.type === "alert"
    ) {
      this.app.thread.copyRmFieldsToThread(
        this.app.alerts.currentSelected,
        alertMapping
      );
    }
    if (
      Object.keys(this.app.seFilter.selectedMatch).length > 0 &&
      this.app.type === "seMatch"
    ) {
      this.app.thread.copyRmFieldsToThread(
        this.app.seFilter.selectedMatch,
        seMatchMapping
      );
    }
  }

  ngOnDestroy(): void {
    if (this._attachmentEdited) {
      this._attachmentEdited.unsubscribe();
    }
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
    if (this._overrideModalSubscription) {
      this._overrideModalSubscription.unsubscribe();
    }
    this.app.thread.isInCreateMode = false;
    this.app.field.resetFieldType(this.app.fieldId.thread.pcnID);
    this.app.field.defaultSettings = [];
    this.app.thread.isNew = false;
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.field.manufacturerCodes = [];
    }
  }
}
