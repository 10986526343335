import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { getApp } from "../app";
import { RM_CLIENT } from "../../../../shared/constants/customers";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  getManufacturersCompressed,
  getThreadsCompressed,
  searchSeManufacturer,
} from "../api.service";
import {
  SeMatchingResult,
  SeSearchPart,
} from "../../../../shared/models/manufacturer";

@Component({
  selector: "app-se-filter",
  templateUrl: "./se-filter.component.html",
  styleUrls: ["./se-filter.component.scss"],
})
export class SeFilterComponent implements OnInit {
  partNumber = "";
  columns: string[] = [];
  columnRows: string[] = [];

  manufacturerId: string | null = "";
  selectedFilters: { columnName: string; value: string }[] = [];
  resetFilterInputs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  resetFilterInputsObservable: Observable<boolean> =
    this.resetFilterInputs.asObservable();
  private redoSearchSubscription: Subscription = new Subscription();
  @Output() searchResult = new EventEmitter<SeMatchingResult[]>();

  constructor(private route: ActivatedRoute) {}
  app = getApp((app) => {
    this.app = app;
  });

  async ngOnInit() {
    this.redoSearchSubscription = this.app.seFilter.redoSearch.subscribe(
      async (value) => {
        if (value) {
          const redoSearch = sessionStorage.getItem("se_filter_cpn");
          if (redoSearch !== null) {
            await this.searchManufacturer(redoSearch);
          }
          sessionStorage.removeItem("se_filter_cpn");
        }
      }
    );

    //if the se_filter_cpn is set, display the old seach result
    let se_filter_cpn = sessionStorage.getItem("se_filter_cpn");
    if (se_filter_cpn != null) {
      this.app.manufacturerMatching.manufacturer.matches =
        this.app.seFilter.matchesCopy;
      this.partNumber = se_filter_cpn != null ? se_filter_cpn : "";
      sessionStorage.removeItem("se_filter_cpn");
    }
    /** get the list of parts that are not cpns so that they can be set
     * when creating a new manufacturer from SE-filter results
     */
    // await this.app.part.getPartsWithoutCpns();

    /**if a search has been done and you navigate between the pages
     * the "redo search" has to happen again, since they get lost
     */
    const redoSearch = sessionStorage.getItem("redoSearch");
    if (redoSearch !== null) {
      await this.searchManufacturer(redoSearch);
    }
    sessionStorage.removeItem("redoSearch");
    this.manufacturerId = this.route.snapshot.paramMap.get("manufacturer");
    this.getColumns();
    this.app.thread.threadsCompressed = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );
    this.app.typeAheadBasic.model["manufacturer.partNumber"] = "";
  }

  /** start the search */
  async searchManufacturer(partNumber: string) {
    this.app.seFilter.searchedPartNumber = partNumber;
    this.app.spinner.showSpinner();
    const seParams: SeSearchPart = {
      partNumber,
    };
    const result = await searchSeManufacturer(
      [seParams],
      this.app.customers.expectCurrent
    );
    this.app.seFilter.getSeSearchResults(result);
    this.resetFilterComponent();
    this.app.spinner.hideSpinner();
    this.searchResult.emit(result);
  }

  getColumns() {
    this.columns = this.app.list.matchingManufacturer.columns;
    this.columnRows = this.app.list.matchingManufacturer.columnRows;
    if (this.manufacturerId == null || this.manufacturerId == undefined) {
      this.app.type = "seMatch";
      this.columnRows.splice(
        this.columnRows.findIndex((i) => i === "select"),
        1
      );
      this.columns.splice(
        this.columns.findIndex((i) => i === "select"),
        1
      );
      this.columnRows.push("createCase");
      this.columns.push("createCase");
      if (this.app.customers.getRMCustomers() === RM_CLIENT) {
        this.columns.unshift("createManufacturer");
        this.columnRows.unshift("createManufacturer");
      }
    } else {
      this.app.type = "manufacturer";
    }
  }

  showValue(column: string, value: string) {
    if (column === "YEOL" && value === "0.0") {
      return false;
    }
    return true;
  }

  resetFilterComponent() {
    this.selectedFilters = [];
    this.resetFilterInputs.next(true);
  }

  ngOnDestroy(): void {
    this.app.manufacturerMatching.manufacturer.matches = [];
  }
}
