<input
  #importFileInput
  type="file"
  accept=".zip"
  style="display: none"
  (change)="uploadPcnZipFile()"
  multiple="true"
  (click)="importFileInput.value = null"
/>
<app-pcn-generator-modal
  #openItemDetail
  [modalTitle]="app.text.pcnGenerator.itemsBox"
  [showItemFields]="app.list.pcnGenerator.itemDetail"
  [fields]="app.list.pcnGenerator.itemDetail"
  [modelDoc]="app.pcnGenerator.item"
  [type]="'discard'"
>
</app-pcn-generator-modal>
<app-modal
  #invalidFileType
  [showButtons]="false"
  [modalMessage]="app.text.pcnGenerator.invalidFileType"
>
</app-modal>
<app-modal
  #invalidFileFormat
  [showButtons]="false"
  [modalMessage]="app.text.pcnGenerator.invalidFileFormat"
>
</app-modal>
<div class="content hero">
  <div class="row justify-content-evenly py-4">
    <!-- SECTION 1 -->
    <div class="col-xl-2 col-lg-2 col-md-8 col-sm-8 col-8 mb-5">
      <!-- Bubble text -->
      <img
        class="mb-4"
        [ngClass]="{
          'user-logo-wider': app.header.isWideLogo(),
          'user-logo': !app.header.isWideLogo()
        }"
        [src]="app.logo"
      />
      <div class="bubble text-center">
        <span class="message">
          {{ app.text.pcnGenerator.clientMessage }}&nbsp;{{
            app.customers.getText()
          }}
        </span>
      </div>
    </div>

    <!-- SECTION 2 -->
    <div
      class="col-xl-6 col-lg-6 col-md-11 col-sm-11 col-11 order-3 order-lg-2"
    >
      <div>
        <!-- SWITCH BTN -->
        <button
          class="btn btn-switch m-0 me-1"
          (click)="app.pcnGenerator.changePcnType(app.pcnGenerator.MANUALLY)"
          [ngClass]="{
            active: app.pcnGenerator.importType === app.pcnGenerator.IMPORT
          }"
        >
          <i class="far fa-edit icon p-1"></i>
          <span class="dataType-text">
            {{ app.text.pcnGenerator.manually }}
          </span>
        </button>
        <button
          class="btn btn-switch m-0 import"
          (click)="app.pcnGenerator.changePcnType(app.pcnGenerator.IMPORT)"
          [ngClass]="{
            active: app.pcnGenerator.importType === app.pcnGenerator.MANUALLY
          }"
        >
          <i class="fa fa-upload icon p-1"></i>
          <span class="dataType-text">
            {{ app.text.pcnGenerator.importPcn }}
          </span>
        </button>
        <!-- IMPORT -->
        <div
          *ngIf="
            app.pcnGenerator.importType === app.pcnGenerator.IMPORT &&
            !app.pcnGenerator.switchContent
          "
        >
          <div class="card card-section p-3">
            <span class="import-text">{{ app.text.pcnGenerator.import }}</span>
            <div class="my-4">
              <button
                class="btn btn-default-orange text-center"
                (click)="importFileInput.click()"
              >
                <i class="fa fa-upload"></i>&nbsp;
                {{ app.text.pcnGenerator.attachPcn }}
              </button>
            </div>
          </div>
        </div>
        <!-- MANUALLY -->
        <div
          class="card card-section"
          *ngIf="
            app.pcnGenerator.importType === app.pcnGenerator.MANUALLY ||
            app.pcnGenerator.switchContent
          "
        >
          <app-pcn-generator-step-bar
            [listBoxes]="app.list.pcnGenerator.boxesLeft"
          >
          </app-pcn-generator-step-bar>
          <!-- <div class="px-4 pb-2">
            <button
              *ngIf="
                app.leftNav.selectedBox != 'pcnGenerator.mainBox' &&
                !app.pcnGenerator.isPcnSent
              "
              class="step-btn"
              (click)="app.pcnGenerator.navigateLeft(app.leftNav.selectedBox)"
            >
              <i class="far fa-arrow-left"></i>
            </button>
            <button
              *ngIf="app.leftNav.selectedBox != 'pcnGenerator.sendBox'"
              class="step-btn pull-right"
              [disabled]="app.pcnGenerator.setNextBtnDisabled()"
              (click)="app.pcnGenerator.navigateRight(app.leftNav.selectedBox)"
            >
              <i class="far fa-arrow-right"></i>
            </button>
          </div> -->
          <div>
            <!-- Boxes -->
            <!-- Master Data -->
            <div
              id="XML"
              class="box"
              *ngIf="app.leftNav.selectedBox == 'pcnGenerator.mainBox'"
            >
              <div class="">
                <app-field
                  *ngFor="
                    let field of app.list.pcnGenerator.mainBox;
                    index as index
                  "
                  [model]="app.pcnGenerator.pcnModel"
                  [field]="field"
                  [index]="index"
                ></app-field>
              </div>
              <div class="mandatory m-1 p-1">
                {{ app.text.pcnGenerator.mandatory_fields_1 }}
                <span style="color: red"
                  ><i class="fal fa-exclamation-circle"></i
                ></span>
                {{ app.text.pcnGenerator.mandatory_fields_2 }}
              </div>
            </div>
            <!-- Items Concerned -->
            <div
              class="box px-4 pb-4"
              *ngIf="app.leftNav.selectedBox == 'pcnGenerator.itemsBox'"
            >
              <button
                class="btn btn-default-orange mb-3"
                (click)="
                  openItemDetail.open();
                  app.pcnGenerator.createModel();
                  app.pcnGenerator.isManually = true
                "
              >
                <i class="fas fa-plus"></i>&nbsp;
                {{ app.text.pcnGenerator.itemDetail }}
              </button>
              <div class="scroll">
                <div
                  class="card card-item my-3"
                  *ngFor="
                    let item of app.pcnGenerator.pcnModel[
                      'pcnGenerator.itemNumbers'
                    ];
                    let index = index
                  "
                >
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div
                        (click)="
                          app.pcnGenerator.createModel(item, index);
                          openItemDetail.open()
                        "
                      >
                        <span class="pointer">
                          {{ index + 1 }})&nbsp;{{
                            item["pcnGenerator.crtNumber"]
                          }}
                        </span>
                      </div>
                      <div>
                        <span
                          class="pointer"
                          (click)="app.pcnGenerator.deleteItem(item)"
                        >
                          <i class="fa fa-trash"></i>
                          {{ app.text.pcnGenerator.delete }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Desc & Attachments -->
            <div
              class="box"
              [hidden]="app.leftNav.selectedBox != 'pcnGenerator.filesBox'"
            >
              <div>
                <app-field
                  *ngFor="
                    let field of app.list.pcnGenerator.filesBox;
                    index as index
                  "
                  [model]="app.pcnGenerator.pcnModel"
                  [field]="field"
                  [index]="index"
                ></app-field>
                <div class="mandatory m-1 p-1">
                  {{ app.text.pcnGenerator.mandatory_fields_1 }}
                  <span style="color: red"
                    ><i class="fal fa-exclamation-circle"></i
                  ></span>
                  {{ app.text.pcnGenerator.mandatory_fields_2 }}
                </div>
              </div>
              <div class="p-4">
                <div class="relevant-doc py-3">
                  {{ app.text.pcnGenerator.relevant_documentation }}
                </div>
                <input
                  #fileInput
                  type="file"
                  accept="*"
                  style="display: none"
                  (change)="uploadAttachment()"
                  multiple
                />
                <button
                  class="btn btn-default-orange"
                  (click)="fileInput.click()"
                  [title]="app.text.attachments.upload"
                >
                  <i class="fa fa-upload"></i>&nbsp;
                  {{ app.text.pcnGenerator.upload_attachment }}
                </button>

                <div
                  *ngFor="
                    let file of app.pcnGenerator.pcnModel[
                      'pcnGenerator._attachments'
                    ];
                    let index = index
                  "
                  class="attachment-name ellipsis"
                >
                  <button
                    type="button"
                    class="btn btn-link"
                    (click)="downloadAttachment(file)"
                  >
                    <span>
                      {{ file.name }}
                    </span>
                  </button>
                  <button
                    class="btn btn-sm m-0 m-0"
                    (click)="deleteAttachment(index)"
                    [ngbTooltip]="app.text.attachments.delete"
                  >
                    <i class="fa fa-trash"></i>
                    {{ app.text.pcnGenerator.delete }}
                  </button>
                </div>
              </div>
            </div>
            <!-- Send box -->
            <div
              *ngIf="app.leftNav.selectedBox == 'pcnGenerator.sendBox'"
              class="send-box text-center m-auto my-3"
            >
              <div *ngIf="!app.pcnGenerator.isPcnSent">
                {{ app.text.pcnGenerator.email_confirmation_yes }}
                <div class="text-center my-3">
                  <div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      pattern="[0-9a-zA-Z]+(?:.[0-9a-zA-Z]+)*@[a-zA-Z0-9]{2,}(?:.[a-zA-z]{2,})+$"
                      #emailref="ngModel"
                      class="form-control"
                      placeholder="{{ app.text.pcnGenerator.email }}"
                      [(ngModel)]="app.pcnGenerator.email"
                      required
                      email
                    />
                    <i
                      *ngIf="emailref.valid"
                      class="fas fa-check-double fa-green m-3"
                    ></i>
                  </div>
                  <div *ngIf="emailref.invalid" class="m-3">
                    {{ app.text.pcnGenerator.invalidEmail }}
                  </div>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-default-orange text-center"
                    (click)="generatePdf(app.pcnGenerator.email)"
                    [disabled]="app.pcnGenerator.isPcnInvalid(emailref.invalid)"
                  >
                    <i class="fa fa-paper-plane"></i>&nbsp;
                    {{ app.text.pcnGenerator.sendBox }}
                  </button>
                </div>
              </div>

              <div
                *ngIf="app.pcnGenerator.isPcnSent"
                class="emailSend mt-4 text-center"
              >
                {{ app.text.pcnGenerator.pcn_send }}
                <div class="text-center mt-4">
                  <button
                    class="btn btn-default-orange text-center"
                    (click)="
                      app.pcnGenerator.changePcnType(app.pcnGenerator.MANUALLY)
                    "
                  >
                    <i class="fa fa-check"></i>&nbsp;{{ app.text.modal.ok }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SECTION 3 -->
    <div class="col-auto order-2 order-lg-3">
      <div class="dropdown">
        <button
          class="btn btn-sm btn-clean dropdown-toggle pull-right"
          type="button"
          data-bs-toggle="dropdown"
        >
          <i class="fa-light fa-globe"></i>&nbsp;
          {{ app.language | uppercase }}
        </button>
        <div class="dropdown-menu dropdown-menu-lang pull-right py-1 px-2">
          <div *ngFor="let language of app.languages">
            <button
              *ngIf="app.customer !== 'ns' && language !== 'nl'"
              class="btn-lang px-3"
              [ngClass]="{ 'btn-lang-selected': language === app.language }"
              (click)="app.language = language"
            >
              {{ language | uppercase }}
            </button>
            <button
              *ngIf="app.customer === 'ns'"
              class="btn-lang px-3"
              [ngClass]="{ 'btn-lang-selected': language === app.language }"
              (click)="app.language = language"
            >
              {{ language | uppercase }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
