import { ComponentSettings, empty } from "../settings/settings";

const settings = {
  permission: {},
  list: {
    boxes: empty,
    main: empty,
  },
  url: {},
  text: {
    main: { de: "Zugang", en: "Account", nl: "Account" },
    errorRetypedPassword: {
      de: "Die Passwörter stimmen nicht überrein",
      en: "Retyped password is different",
      nl: "Het opnieuw getypte wachtwoord is anders",
    },
    oldPasswordIncorrect: {
      de: "Das alte Passwort ist falsch",
      en: "The old password is incorrect",
      nl: "Het oude wachtwoord is onjuist",
    },
    passwordNotString: {
      de: "Das Passwort ist keine Zeichenfolge",
      en: "Password is not a string",
      nl: "Wachtwoord is geen string",
    },
    passwordCount1: {
      de: "Das Passwort ist nicht mindestens ",
      en: "Password is not at least ",
      nl: "Wachtwoord is niet ten minste ",
    },
    passwordCount2: {
      de: " Zeichen lang",
      en: " characters long",
      nl: " tekens lang",
    },
    passwodSpecialCharacters: {
      de: "Das Passwort enthält keines der folgenden Sonderzeichen: ",
      en: "Password does not contain one of the following special characters: ",
      nl: "Wachtwoord bevat niet een van de volgende speciale tekens: ",
    },
    passwordNumber: {
      de: "Das Passwort enthält keine Nummer",
      en: "Password does not contain a number",
      nl: "Wachtwoord bevat geen cijfer",
    },
    succesUserUpdated: {
      de: "Benutzer erfolgreich aktualisiert",
      en: "User updated successfully",
      nl: "Gebruiker succesvol bijgewerkt",
    },
    passwordChange: {
      de: "Passwort ändern",
      en: "Change password",
      nl: "Wijzig wachtwoord",
    },
  },
  field: {},
};

export const profilePassword: ComponentSettings<typeof settings> = settings;
