const stringFormat = /[!^()\=\[\]{};':"\\|<>\/?#$%*]+/;

export class StringUtils {
  public static isNullOrEmpty(text: string | undefined): boolean {
    if (text == null || text === "" || text == undefined) {
      return true;
    }

    return false;
  }

  public static isUndefined(text: string | undefined): boolean {
    if (text == undefined) {
      return true;
    }

    return false;
  }

  public static containsSpecialCharacter(value: string, f?: RegExp) {
    let format = f != null ? f : stringFormat;
    if (format.test(value)) {
      return true;
    }
    return false;
  }
}
