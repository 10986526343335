import { FieldTypes, FilterTypes, empty } from "../settings/settings";
import { ComponentSettings } from "../settings/settings";

export const obsolescenceStatusOptions = {
  obsolete: {
    text: { de: "Obsolete", en: "Obsolete", nl: "Obsolete" },
    color: "#EB5757",
  },
  "PDN issued": {
    text: { de: "PDN issued", en: "PDN issued", nl: "PDN uitgegeven" },
    color: "#FA9C1B",
  },
  nrnd: {
    text: { de: "NRND", en: "NRND", nl: "NRND" },
    color: "#F2C94C",
  },
  active: {
    text: { de: "Active", en: "Active", nl: "Actief" },
    color: "#27AE60",
  },
  resolved: {
    text: { de: "Ignored", en: "Ignored", nl: "Opgelost" },
    color: "#7777FF",
  },
  unconfirmed: {
    text: { de: "Unconfirmed", en: "Unconfirmed", nl: "Onbevestigd" },
    color: "#F7F7F7",
  },
};

export const obsolescenceStatusFields = {
  obsolescenceStatus: {
    type: FieldTypes.options,
    text: {
      de: "Aktueller Obsoleszenzstatus",
      en: "Current Obsolescence Status",
      nl: "Actuele Obsolescence Status",
    },
    options: obsolescenceStatusOptions,
    mutable: false,
  },
  /*obsolescenceStatusOverride: {
    text: { de: "Status manuell gesetzt", en: "Manual override", nl: "Status handmatig ingesteld" },
    type: FieldTypes.checkbox,
    search: false
  },*/
  obsolescenceStatus2years: {
    type: FieldTypes.options,
    text: { de: "Vorhersage 2 Jahre", en: "2 Years Forecast", nl: "2 jaar" },
    options: obsolescenceStatusOptions,
    mutable: false,
    search: true,
  },
  obsolescenceStatus4years: {
    type: FieldTypes.options,
    text: { de: "Vorhersage 4 Jahre", en: "4 Years Forecast", nl: "4 jaar" },
    options: obsolescenceStatusOptions,
    mutable: false,
    search: true,
  },
  obsolescenceStatus6years: {
    type: FieldTypes.options,
    text: { de: "Vorhersage 6 Jahre", en: "6 Years Forecast", nl: "6 jaar" },
    options: obsolescenceStatusOptions,
    mutable: false,
    search: true,
  },
  obsolescenceStatus8years: {
    type: FieldTypes.options,
    text: { de: "Vorhersage 8 Jahre", en: "8 Years Forecast", nl: "8 jaar" },
    options: obsolescenceStatusOptions,
    mutable: false,
    search: true,
  },
};

export const obsolescencelifeCycleRiskOptions = {
  high: {
    text: { de: "High", en: "High" },
    color: "#EB5757",
  },
  medium: {
    text: { de: "Medium", en: "Medium" },
    color: "#F2C94C",
  },
  low: {
    text: { de: "Low", en: "Low" },
    color: "#27AE60",
  },
  unknown: {
    text: { de: "Unknown", en: "Unknown" },
    color: "#F7F7F7",
  },
};

export const likelihoodConst = {
  likelihood: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzwahrscheinlichkeit",
      en: "Obsolescence Likelihood",
      nl: "Waarschijnlijkheid",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
  },
};

export const leadTimeConst = {
  leadTime: {
    type: FieldTypes.options,
    text: {
      de: "Hersteller Lieferzeiten",
      en: "Manufacturer Lead Time",
      nl: "Levertijden fabrikant",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
  },
};

export const riskAssessmentFields = {
  ...likelihoodConst,
  impact: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzauswirkung",
      en: "Obsolescence Impact",
      nl: "Obsolescence Impact",
    },
    options: obsolescencelifeCycleRiskOptions,
    sort: false,
  },
  impact_1: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzauswirkung basierend auf ersten Parametern",
      en: "Obsolescence Impact based on first parameters",
      nl: "Impact op basis van eerste gekozen parameter",
    },
    options: obsolescencelifeCycleRiskOptions,
    sort: false,
    search: false,
  },
  impact_2: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzauswirkung basierend auf zweiten Parametern",
      en: "Obsolescence Impact based on second parameters",
      nl: "Impact op basis van tweede parameters",
    },
    options: obsolescencelifeCycleRiskOptions,
    sort: false,
    search: false,
  },
  totalRisk: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzrisiko",
      en: "Obsolescence Risk",
      nl: "Risico",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
  },
};

export const euRoHSStatusOptions = {
  no: {
    text: { de: "No", en: "No", nl: "Nee" },
    color: "#EB5757",
  },
  "yes with exemption": {
    text: {
      de: "Yes with Exemption",
      en: "Yes with Exemption",
      nl: "Ja met vrijstelling",
    },
    color: "#F2C94C",
  },
  yes: {
    text: { de: "Yes", en: "Yes" },
    color: "#27AE60",
  },
  "not required": {
    text: { de: "Not Required", en: "Not Required", nl: "Niet verplicht" },
    color: "#F7F7F7",
  },
  unknown: {
    text: { de: "Unknown", en: "Unknown", nl: "Onbekend" },
    color: "#F7F7F7",
  },
};

export const euRoHSStatusFields = {
  euRoHS: {
    type: FieldTypes.options,
    text: { de: "EU RoHS Status", en: "EU RoHS Status", nl: "EU RoHS Status" },
    options: euRoHSStatusOptions,
  },
};

export const reachField = {
  reachAffected: {
    type: FieldTypes.options,
    text: {
      de: "REACH betroffen",
      en: "REACH affected",
      nl: "REACH betrokken",
    },
    options: {
      yes: {
        text: {
          de: "Yes",
          en: "Yes",
        },
        color: "#EB5757",
      },
      no: {
        text: {
          de: "No",
          en: "No",
        },
        color: "#27AE60",
      },
      unknown: {
        text: {
          de: "Unknown",
          en: "Unknown",
        },
        color: "#F7F7F7",
      },
    },
  },
};

class ProductCategory {
  categoryName: string = "";
  subcategories: string[] = [];
}

export const productCategoryTypes: ProductCategory[] = [
  {
    categoryName: "ELME",
    subcategories: [
      "Sensor and Transducers",
      "Circuit Protection",
      "Thermal Management",
      "Batteries and Power Supplies",
      "Process Control",
      "Electromechanical",
      "Oscillators and Crystals",
    ],
  },
  {
    categoryName: "ASSY",
    subcategories: [
      "Communication",
      "Oscilloscopes, Generators and Analyzers",
      "Computer Products",
      "Displays",
      "Electrical and Electronic Test Equipment",
      "Test and Measurement Accessories",
    ],
  },
  {
    categoryName: "OTHR",
    subcategories: ["Tools and Supplies"],
  },
  {
    categoryName: "MECH",
    subcategories: [
      "Fasteners and Hardware",
      "Motion Control and Fluid Power",
      "Mechanical Power Transmission",
    ],
  },
  {
    categoryName: "ACEL",
    subcategories: [
      "Drivers and Interfaces",
      "Power Management",
      "RF and Microwave",
      "Controllers",
      "Optoelectronics",
      "Memory",
      "Standard and Specialty Logic",
      "Standard and Special Logic",
      "Multimedia",
      "Diodes, Transistors and Thyristors",
      "Development Systems",
      "Data Acquisition",
      "Electronic Switches",
      "Amplifiers and Comparators",
      "Linear",
      "Microcontrollers and Processors",
      "Clock and Timing",
      "Programmable Devices",
      "Transceivers",
      "Embedded Controllers and Systems",
      "Attenuators",
      "Peripherals",
    ],
  },
  {
    categoryName: "PAEL",
    subcategories: [
      "Magnetics",
      "LEDs and LED Lighting",
      "Capacitors",
      "Resistors",
      "EMI/RFI Suppression",
    ],
  },
  {
    categoryName: "HYDR",
    subcategories: ["Fluid Transfer"],
  },
  {
    categoryName: "CCBL",
    subcategories: ["Connectors", "Wire and Cable"],
  },
];
const statusRisk = {
  type: FieldTypes.options,
  text: {
    de: "Kritikalität",
    en: "Criticality",
  },
  options: {
    high: {
      text: {
        en: "Service / Safety critical",
        de: "Betriebs / Sicherheitskritisch",
      },
    },
    medium: { text: { en: "Mission critical", de: "Missionskritisch" } },
    low: { text: { en: "None of the above", de: "Weder noch" } },
  },
  required: true,
  search: false,
};

const productCategory = {
  type: FieldTypes.options,
  text: {
    de: "Kategorie der Einheit",
    en: "Category of the Item",
    nl: "Item product categoie",
  },
  options: {
    ACEL: {
      text: {
        de: "Aktive Elektronik",
        en: "Active Electronic",
        nl: " Actieve Elektronica",
      },
      impact: "medium",
    },
    DACE: {
      text: {
        de: "Daten / Zertifikat",
        en: "Data / Certificates",
        nl: "Data / Certificaten",
      },
      impact: "high",
    },
    SERV: {
      text: {
        de: "Dienstleistung",
        en: "Services",
        nl: "Diensten",
      },
      impact: "medium",
    },
    DOCU: {
      text: {
        de: "Dokumentation",
        en: "Documentation",
        nl: "Documentatie",
      },
      impact: "medium",
    },
    ELME: {
      text: {
        de: "Elektromechanik",
        en: "Electro Mechanical",
        nl: "Elektromechanisch",
      },
      impact: "low",
    },
    FLUI: {
      text: {
        de: "Fluid",
        en: "Fluids",
        nl: "Vloeistoffen",
      },
      impact: "low",
    },
    AUXM: {
      text: {
        de: "Hilfsmaterial",
        en: "Auxiliary Materials",
        nl: "Hulpmateriaal",
      },
      impact: "low",
    },
    HYDR: {
      text: {
        de: "Hydraulik",
        en: "Hydraulic",
        nl: "Hydraulisch",
      },
      impact: "medium",
    },
    MECH: {
      text: {
        de: "Mechanik",
        en: "Mechanical",
        nl: "Mechanisch",
      },
      impact: "medium",
    },
    MULT: {
      text: {
        de: "Mehrere Kategorien",
        en: "Multiple Categories",
        nl: "Verschillende Categorieën",
      },
      impact: "high",
    },
    PAEL: {
      text: {
        de: "Passive Elektrik / Elektronik",
        en: "Passive Electrical / Electronic",
        nl: "Passieve Elektrisch / Elektronisch",
      },
      impact: "low",
    },
    PNEU: {
      text: {
        de: "Pneumatik",
        en: "Pneumatic",
        nl: "Pneumatisch",
      },
      impact: "medium",
    },
    RAWM: {
      text: {
        de: "Rohmaterial",
        en: "Raw Material",
        nl: "Basismateriaal",
      },
      impact: "low",
    },
    SWFW: {
      text: {
        de: "Software / Firmware",
        en: "Software / Firmware",
        nl: "Software / Firmware",
      },
      impact: "high",
    },
    OTHR: {
      text: {
        de: "Sonstige",
        en: "Other",
        nl: "Overige",
      },
      impact: "unknown",
    },
    CCBL: {
      text: {
        de: "Steckverbinder / Kabel",
        en: "Connectors / Cables",
        nl: "Stekkers / Kabels",
      },
      impact: "low",
    },
    ASSY: {
      text: {
        de: "Zusammenbau",
        en: "Assembly",
        nl: "Assemblage",
      },
      impact: "medium",
    },
  },
  search: false,
};

const field = {
  partNumber: {
    type: FieldTypes.value,
    text: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    filter: FilterTypes.startsWith,
    mutable: false,
  },
  obsolescenceManagement: {
    type: FieldTypes.radio,
    text: {
      de: "Proaktive Einheit",
      en: "Proactive Item",
      nl: "Proactief",
    },
    options: {
      true: { text: { de: "Ja", en: "Yes", nl: "Ja" } },
      false: { text: { de: "Nein", en: "No", nl: "Nee" } },
    },
  },
  description: {
    type: FieldTypes.value,
    text: {
      de: "Bezeichnung der Einheit",
      en: "Designation of the Item",
      nl: "Artikelomschrijving",
    },
    search: false,
  },
  ...obsolescenceStatusFields,
  ...riskAssessmentFields,
  ...euRoHSStatusFields,
  ...reachField,
  ...leadTimeConst,
  parts: {
    text: { de: "Stückliste", en: "Bill of Material", nl: "Stuklijst" },
    type: FieldTypes.data,
    search: false,
  },
  level: {
    type: FieldTypes.options,
    text: {
      de: "Level",
      en: "Level",
      nl: "Niveau",
    },
    options: {
      part: { text: { de: "Bauteil", en: "Part", nl: "Onderdeel" } },
      assembly: {
        text: { de: "Baugruppe", en: "Assembly", nl: "Samenstelling" },
      },
      product: { text: { de: "Produkt", en: "Product", nl: "Product" } },
    },
    search: false,
  },
  responsibleName: {
    type: FieldTypes.value,
    text: { de: "Verantwortlich", en: "Responsible", nl: "Verantwoordelijk" },
    search: false,
  },
  responsibleDepartment: {
    type: FieldTypes.value,
    text: { de: "Abteilung", en: "Department", nl: "Afdeling" },
    search: false,
  },
  productCategory,
  statusRisk,
  internalCategory: {
    type: FieldTypes.options,
    text: {
      de: "Interne Kategorie",
      en: "Internal Category",
    },
    options: {
      CPR: {
        text: {
          de: "Catalog Part Revision",
          en: "Catalog Part Revision",
        },
      },
      SMA: {
        text: {
          de: "SMA Specified Part Revision",
          en: "SMA Specified Part Revision",
        },
      },
      SPR: {
        text: {
          de: "Semifinished Product Revision",
          en: "Semifinished Product Revision",
        },
      },
    },
    search: false,
  },
  productAcronym: {
    type: FieldTypes.value,
    text: { de: "Acronym", en: "Short name", nl: "Verkorte naam" },
    search: false,
  },
  productOwner: {
    type: FieldTypes.value,
    text: { de: "Product Owner", en: "Product owner", nl: "Product eigenaar" },
    search: false,
  },
  productPmName: {
    type: FieldTypes.value,
    text: { de: "PM Name", en: "PM name", nl: "PM naam" },
    search: false,
  },
  productPm: {
    type: FieldTypes.value,
    text: { de: "PM", en: "PM", nl: "PM" },
    search: false,
  },
  productStatus: {
    type: FieldTypes.value,
    text: { de: "Status", en: "Status", nl: "Status" },
    search: false,
  },
  productSOP: {
    type: FieldTypes.date,
    text: { de: "Launch Date", en: "Launch Date", nl: "Start datum" },
    csv: "SOP",
    search: false,
  },
  productEOP: {
    type: FieldTypes.date,
    text: {
      de: "Discontinuation Date",
      en: "Discontinuation Date",
      nl: "Discontinue datum",
    },
    csv: "EOP",
    search: false,
  },
  forecast: {
    type: FieldTypes.value,
    text: { de: "Bedarf", en: "Forecast", nl: "Prognose" },
    search: false,
  },
  purchaseId: {
    type: FieldTypes.value,
    text: { de: "Einkauf", en: "Purchase ID", nl: "Inkoop ID" },
    search: false,
  },
  usedInPartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Kundenteilenummer (KTN) (Verwendet in)",
      en: "Customer Part Number (CPN) (used in)",
      nl: "Artikel Nummer (CPN) (gebruikt in)",
    },
    search: false,
  },
  usedInQuantity: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl (Verwendet in)",
      en: "Quantity (Used in)",
      nl: "Aantal (gebruikt in)",
    },
    search: false,
  },
  sapDescription: {
    type: FieldTypes.value,
    text: { de: "SAP Kurztext", en: "SAP description", nl: "SAP omschrijving" },
    search: false,
  },
  sapNumber: {
    type: FieldTypes.value,
    text: { de: "SAP Nr.", en: "SAP Nr.", nl: "SAP Nr." },
    search: false,
    maxLength: 40,
  },
  turnOverCurrentYear: {
    text: {
      de: "Jahre",
      en: "Year",
      nl: "jaren",
    },
    type: FieldTypes.text,
    search: false,
  },
  turnOverQuantity: {
    text: {
      de: "Turnover",
      en: "Turnover",
      nl: "Omzet",
    },
    type: FieldTypes.text,
    search: false,
  },
  turnOverPrice: {
    text: {
      de: "Price",
      en: "Price",
      nl: "Prijs",
    },
    type: FieldTypes.text,
    search: false,
  },
  equivalentInformation: {
    type: FieldTypes.value,
    text: {
      de: "Äquivalente Informationen",
      en: "Equivalent Information",
      nl: "Alternatieve informatie",
    },
    search: false,
  },
  productNumber: {
    type: FieldTypes.value,
    text: {
      de: "Produktnummer",
      en: "Product Number",
      nl: "Productnummer",
    },
    search: false,
  },
  productName: {
    type: FieldTypes.value,
    text: {
      de: "Produktname",
      en: "Product Name",
      nl: "Productnaam",
    },
    search: false,
  },
  quantity: {
    type: FieldTypes.value,
    text: {
      de: "Menge",
      en: "Quantity",
      nl: "Aantal",
    },
    search: false,
  },

  proactivelyCriteria: {
    type: FieldTypes.options,
    text: {
      de: "Filterkriterien",
      en: "Filter Criteria",
      nl: "Filtercriteria",
    },
    multiple: true,
    options: {
      trainPart: {
        text: {
          de: "Nicht in Zugserie verwendet",
          en: "Not used in train series",
          nl: "Niet gebruikt in treinseries",
        },
      },
      normed: {
        text: {
          de: "Normiert",
          en: "Normed",
          nl: "Genormeerd",
        },
      },
      projectStatus: {
        text: {
          de: "Inaktive Einheit",
          en: "Inactive Item",
          nl: "Inactief Item",
        },
      },
      classNumber: {
        text: {
          de: "Keine relevante LIMA-Klassifizierung",
          en: "No relevant LIMA classification",
          nl: "Geen relevante LIMA-classificatie",
        },
      },
      logistic: {
        text: {
          de: "Irrelevanter Einheitentyp (Logistik)",
          en: "Irrelevant item type (logistics)",
          nl: "Onrelevant item type (logistiek)",
        },
      },
      itemType: {
        text: {
          de: "Nicht manuell verwaltet",
          en: "Not managed manually",
          nl: "Niet manueel beheerd",
        },
      },
    },
    search: false,
  },
  detailView: {
    type: FieldTypes.value,
    text: {
      de: "Siehe Detailansicht",
      en: "See detailed View",
      nl: "Zie detailweergave",
    },
    search: false,
  },
  partsToVehicles: {
    type: FieldTypes.value,
    text: {
      de: "Wo verwendet?",
      en: "Where-Used?",
      nl: "Waar gebruikt?",
    },
    search: false,
  },
  threadCase: {
    type: FieldTypes.value,
    text: {
      de: "Siehe Fallansicht",
      en: "See Case View",
      nl: "Bekijk de issue",
    },
    search: false,
  },
  matchedSystem: {
    type: FieldTypes.value,
    text: {
      de: "Match system",
      en: "Match-System",
    },
    search: false,
  },
  itemType: {
    type: FieldTypes.options,
    text: {
      de: "Typ der Einheit",
      en: "Item Type",
      nl: "Artikeltype",
    },
    options: {
      SLIJT: { text: { de: "SLIJT", en: "SLIJT", nl: "SLIJT" } },
      WISSEL_INTERN_NEWBUY: {
        text: {
          de: "WISSEL INTERN NEWBUY",
          en: "WISSEL INTERN NEWBUY",
          nl: "WISSEL INTERN NEWBUY",
        },
      },
      WISSEL_INTERN_REPAIR: {
        text: {
          de: "WISSEL INTERN REPAIR",
          en: "WISSEL INTERN REPAIR",
          nl: "WISSEL INTERN REPAIR",
        },
      },
      WISSEL_EXTERN_NEWBUY: {
        text: {
          de: "WISSEL EXTERN NEWBUY",
          en: "WISSEL EXTERN NEWBUY",
          nl: "WISSEL EXTERN NEWBUY",
        },
      },
      WISSEL_EXTERN_REPAIR: {
        text: {
          de: "WISSEL EXTERN REPAIR",
          en: "WISSEL EXTERN REPAIR",
          nl: "WISSEL EXTERN REPAIR",
        },
      },
      FAC: { text: { de: "FAC ", en: "FAC ", nl: "FAC" } },
    },
    search: false,
  },
  classNumber: {
    type: FieldTypes.value,
    text: {
      de: "LIMA Klasse",
      en: "LIMA Class",
      nl: "Limaclassificatie",
    },
    search: false,
  },
  usedInSystem: {
    type: FieldTypes.options,
    text: {
      de: "Verwendet in",
      en: "Used in System",
      nl: "Gebruikt in Systeem",
    },
    multiple: true,
    options: {
      ALGEMEEN: { text: { de: "Algemeen", en: "Algemeen", nl: "Algemeen" } },
      DDAR: { text: { de: "DDAR", en: "DDAR", nl: "DDAR" } },
      DDM1: { text: { de: "DDM1", en: "DDM1", nl: "DDM1" } },
      DDZ: { text: { de: "DDZ", en: "DDZ", nl: "DDZ" } },
      E1700: { text: { de: "E1700", en: "E1700", nl: "E1700" } },
      FACILITAIR: {
        text: { de: "Facilitair", en: "Facilitair", nl: "Facilitair" },
      },
      FLIRTHRN: { text: { de: "Flirt-HRN", en: "Flirt-HRN", nl: "Flirt-HRN" } },
      FLIRTTAG: { text: { de: "Flirt-TAG", en: "Flirt-TAG", nl: "Flirt-TAG" } },
      ICE: { text: { de: "ICE", en: "ICE", nl: "ICE" } },
      ICM: { text: { de: "ICM", en: "ICM", nl: "ICM" } },
      ICR: { text: { de: "ICR", en: "ICR", nl: "ICR" } },
      LINT: { text: { de: "LINT", en: "LINT", nl: "LINT" } },
      LOC700: { text: { de: "LOC700", en: "LOC700", nl: "LOC700" } },
      SGM: { text: { de: "SGM", en: "SGM", nl: "SGM" } },
      SLT: { text: { de: "SLT", en: "SLT", nl: "SLT" } },
      TRAXX: { text: { de: "TRAXX", en: "TRAXX", nl: "TRAXX" } },
      VIRM1: { text: { de: "VIRM-1", en: "VIRM-1", nl: "VIRM-1" } },
      VIRM23: { text: { de: "VIRM-2/3", en: "VIRM-2/3", nl: "VIRM-2/3" } },
      VIRM4: { text: { de: "VIRM-4", en: "VIRM-4", nl: "VIRM-4" } },
      VIRMM1: { text: { de: "VIRM-M1", en: "VIRM-M1", nl: "VIRM-M1" } },
      VIRM: { text: { de: "VIRM", en: "VIRM", nl: "VIRM" } },
    },
    search: false,
  },
  norm: {
    type: FieldTypes.value,
    text: {
      de: "Norm",
      en: "Norm",
      nl: "Norm",
    },
    search: false,
  },
  readilyAvailableStock: {
    type: FieldTypes.number,
    text: {
      de: "Verfügbarer Lagerbestand",
      en: "Readily available Stock",
      nl: "Voorraad schoon",
    },
    search: false,
  },
  actualStock: {
    type: FieldTypes.number,
    text: {
      de: "Gesamter Lagerbestand",
      en: "Total Stock",
      nl: "Totaal Voorraad",
    },
    search: false,
  },
  safetyStock: {
    type: FieldTypes.number,
    text: {
      de: "Sicherheitsbestand",
      en: "Safety Stock",
      nl: "MIN safety stock",
    },
    search: false,
  },
  repairable: {
    type: FieldTypes.checkbox,
    text: {
      de: "Reparierbare Einheit",
      en: "Repairable Item",
      nl: "Wisseldeel",
    },
    search: false,
  },
  sourceAvailable: {
    type: FieldTypes.number,
    text: {
      de: "Anzahl Bezugsquellen",
      en: "Number of Sources",
    },
    search: false,
  },

  totalLifeCycleUsage: {
    type: FieldTypes.number,
    text: {
      de: "Bedarfsvorhersage bis zum Lebenszyklusende",
      en: "Life Cycle Demand Forecast",
      nl: "Prognose EOL",
    },
    search: false,
  },
  totalForecast2Years: {
    type: FieldTypes.number,
    text: {
      de: "2 Jahres - Bedarfsvorhersage",
      en: "2 years demand Forecast",
      nl: "Prognose 2 jaar",
    },
    search: false,
  },
  forecastEOLStock: {
    type: FieldTypes.number,
    text: {
      de: "Vorhersage des Bestands am Lebenszyklusende",
      en: "Forecast Stock Level at EOL",
      nl: "Voorraad - EOL eindwaarde",
    },
    search: false,
  },
  stockLevelDeclination: {
    type: FieldTypes.number,
    text: {
      de: "Vorratsdezimierungsrate",
      en: "Declination factor for Stock Forecast",
      nl: "Forecast prognose voorraad afbouw door uitval",
    },
    search: false,
  },
  stockRange: {
    type: FieldTypes.number,
    text: {
      de: "Stock Range in Months",
      en: "Stock Range in Months",
      nl: "Stock Range in Months",
    },
    search: false,
  },
  stockRangeUntil: {
    type: FieldTypes.date,
    text: {
      de: "Reichweite (PS3) bis",
      en: "Range (PS3) until",
      nl: "Range (PS3) until",
    },

    mutable: false,
    search: false,
  },
  manualMonitoringRange: {
    type: FieldTypes.number,
    text: {
      de: "Manuelle Überwachungsreichweite (PS3)",
      en: "Manual monitoring Range (PS3)",
      nl: "Manual monitoring Range (PS3)",
    },
    search: false,
  },
  standardRange: {
    type: FieldTypes.number,
    text: {
      de: "Standard Reichweite (PS3)",
      en: "Standard Range (PS3)",
      nl: "Standard Range (PS3)",
    },
    search: false,
    maxLength: 1000,
  },
  monitoringRangeUpTo: {
    type: FieldTypes.date,
    text: {
      de: "Zur Überwachende Reichweite (PS3) bis",
      en: "Monitoring range (PS3) up to",
      nl: "Monitoring range (PS3) up to",
    },
    search: false,
  },
  globalStock: {
    type: FieldTypes.number,
    text: {
      de: "weltweiter Bestand",
      en: "Global Stock",
      nl: "Global Stock",
    },
    mutable: false,
    search: false,
  },
  minimumStock: {
    type: FieldTypes.number,
    text: {
      de: "Mindestbestand",
      en: "Minimum Stock",
      nl: "Minimum Stock",
    },
    search: false,
  },
  purchasePartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Typenbezeichnung",
      en: "Type Designation",
      nl: "Typeaanduiding",
    },
    filter: FilterTypes.startsWith,
    search: false,
  },
  partEditedFields: {
    type: FieldTypes.value,
    text: {
      de: "Part Fields eddited",
      en: "Part Fields eddited",
      nl: "Part Fields eddited",
    },
    search: false,
    multiple: true,
  },
  numberSecSource: {
    type: FieldTypes.value,
    text: {
      de: "Nr. der gültigen Hersteller",
      en: "No. of valid manufacturers",
      nl: "Aantal geldige fabrikanten",
    },
    search: false,
  },
  likelihood_secSource: {
    type: FieldTypes.options,
    text: {
      de: "Wahrscheinlichkeit (Anzahl aktive & freigegebene Alternativen)",
      en: "Likelihood (Number of Active & Approved 2nd Sources)",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
    search: false,
  },
  freqUse: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl der betroffenen Baugruppen",
      en: "No. of affected assemblies",
      nl: "Aantal betrokken assemblages",
    },
    search: false,
  },
  freqUseImpact: {
    type: FieldTypes.options,
    text: {
      de: "Auswirkung (Häufigkeit)",
      en: "Impact (Frequency)",
    },
    options: obsolescencelifeCycleRiskOptions,
    search: false,
    mutable: false,
  },
  sapStatus: {
    type: FieldTypes.value,
    text: {
      de: "Materialstatus (SAP)",
      en: "Material status (SAP)",
    },
    search: false,
  },
  designType: {
    type: FieldTypes.value,
    text: {
      de: "Bauart / Typ",
      en: "Design / Type",
    },
    search: false,
  },
  catComplexity: {
    type: FieldTypes.options,
    text: {
      de: "Komplexität",
      en: "Complexity",
    },
    options: {
      integrated_circuits: {
        text: { de: "Integrated Circuits", en: "Integrated Circuits" },
      },
      transistors: { text: { de: "Transistors", en: "Transistors" } },
      chrystals: { text: { de: "Chrystals", en: "Chrystals" } },
      dc_dc_converter: {
        text: { de: "DC/DC Converter", en: "DC/DC Converter" },
      },
      relais: { text: { de: "Relais", en: "Relais" } },
      z_diodes: { text: { de: "Z-Diodes", en: "Z-Diodes" } },
      transformer: { text: { de: "Transformer", en: "Transformer" } },
      drawings: { text: { de: "Drawings", en: "Drawings" } },
      schemes: { text: { de: "Schemes", en: "Schemes" } },
      prints: { text: { de: "Prints", en: "Prints" } },
      resistors: { text: { de: "Resistors", en: "Resistors" } },
      diodes: { text: { de: "Diodes", en: "Diodes" } },
      utilities: { text: { de: "Utilities", en: "Utilities" } },
      software: {
        text: { de: "Int. / Ext. Software", en: "Int. / Ext. Software" },
      },
      unknown: {
        text: { de: "Unbekannt, Sonstige", en: "Unbekannt, Sonstige" },
      },
    },
    search: false,
  },
  catComplexityImpact: {
    type: FieldTypes.options,
    text: {
      de: "Auswirkung (Komplexität)",
      en: "Impact (Complexity)",
    },
    options: obsolescencelifeCycleRiskOptions,
    search: false,
    mutable: false,
  },
  posType: {
    type: FieldTypes.value,
    text: {
      de: "Positionstyp",
      en: "Position Type",
    },
    search: false,
  },
  matType: {
    type: FieldTypes.value,
    text: {
      de: "Materialart",
      en: "Type of Material",
    },
    search: false,
  },
  stockPAS: {
    type: FieldTypes.number,
    text: {
      de: "Bestand (PAS)",
      en: "Stock (PAS)",
    },
    search: false,
  },
  stockPS2: {
    type: FieldTypes.number,
    text: {
      de: "Bestand (PS2)",
      en: "Stock (PS2) ",
    },
    search: false,
  },
  stockMS5: {
    type: FieldTypes.number,
    text: {
      de: "Bestand (MS5)",
      en: "Stock (MS5)",
    },
    search: false,
  },
  stockFrom: {
    type: FieldTypes.number,
    text: {
      de: "Bestand zum",
      en: "Stock from",
    },
    search: false,
  },
  stockRangeYears: {
    type: FieldTypes.number,
    text: {
      de: "Reichweite in Jahre (auf Basis Abflüsse letzte 12 Monate)",
      en: "Range in years",
    },
    search: false,
  },
  outflows: {
    type: FieldTypes.number,
    text: {
      de: "Abflüsse (letzte 12 Monate)",
      en: "Outflows (last 12 months)",
    },
    search: false,
  },
  sapComments: {
    type: FieldTypes.value,
    text: {
      de: "SAP Kommentare",
      en: "SAP Comments",
    },
    search: false,
  },
  config: {
    type: FieldTypes.checkbox,
    text: {
      de: "Konfig Management relevant",
      en: "Config Managment relevant",
    },
    search: false,
  },
  emissionEMREL: {
    type: FieldTypes.checkbox,
    text: {
      de: "Emissionsrelevant/ EMREL?",
      en: "Emission relevant EMREL?",
    },
    search: false,
  },
  nuclearPower: {
    type: FieldTypes.value,
    text: {
      de: "Nuclear Power Plant (KKW)",
      en: "Nuclear Power Plant (KKW)",
    },
    search: false,
  },
  responsibleOM: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlich OM",
      en: "Responsible OM",
    },
    search: false,
  },
  responsibleRD: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher F&E",
      en: "Responsible R&D",
    },
    search: false,
  },
  partner: {
    type: FieldTypes.value,
    text: {
      de: "Lieferant",
      en: "Supplier",
    },
    search: false,
  },
  product: {
    type: FieldTypes.value,
    text: {
      de: "Produktverantwortlich",
      en: "Product responsible",
    },
    search: false,
  },
  startSerie: {
    type: FieldTypes.date,
    text: { de: "Start Serie", en: "Start Serie" },
    search: false,
  },
  endSerie: {
    type: FieldTypes.date,
    text: { de: "Ende Serie", en: "End Serie" },
    search: false,
  },
  endDelivery: {
    type: FieldTypes.date,
    text: {
      de: "Ende Lieferverpflichtung",
      en: "End of delivery obligation",
    },
    search: false,
  },
  startRedesign: {
    type: FieldTypes.value,
    text: {
      de: "Start Re-Design",
      en: "Planned start of re-design",
    },
    search: false,
  },
  electronicalMat: {
    type: FieldTypes.value,
    text: {
      de: "Elektronik-Mat (SAP)",
      en: "Electronical-Mat (SAP)",
    },
    search: false,
  },
  inventoryMonitoring: {
    type: FieldTypes.checkbox,
    text: {
      de: "Bestandsüberwachung",
      en: "Inventory Monitoring",
      nl: "Inventory Monitoring",
    },
    search: false,
  },
  hazardousSubstance: {
    type: FieldTypes.value,
    text: {
      de: "Gefahrstoff",
      en: "Hazardous Substance",
      nl: "Hazardous Substance",
    },
    search: false,
  },
  productSafety: {
    type: FieldTypes.value,
    text: {
      de: "Produktsicherheitsgesetz",
      en: "Product Safety Act",
      nl: "Product Safety Act",
    },
    search: false,
  },
  extent: {
    type: FieldTypes.value,
    text: {
      de: "Schadensausmaß (D-Teil)",
      en: "Extent of Damage (D-part)",
      nl: "Extent of Damage (D-part)",
    },
    search: false,
  },
  componentApproval: {
    type: FieldTypes.value,
    text: {
      de: "Zulassungspflichtiges Bauteil",
      en: "Component Requiring Approval",
      nl: "Component Requiring Approval",
    },
    search: false,
  },
  exemption: {
    type: FieldTypes.value,
    text: {
      de: "Ausnahme",
      en: "Exemption",
      nl: "Exemption",
    },
    search: false,
  },
  devStatus: {
    type: FieldTypes.options,
    text: {
      en: "Development Status",
      de: "Entwicklung Status",
    },
    options: {
      standard: { text: { de: "Standard", en: "Standard" } },
      preferred: { text: { de: "Preferred", en: "Preferred" } },
      unfavored: { text: { de: "Unfavored", en: "Unfavored" } },
      not_for_nd: {
        text: { de: "Not for new design", en: "Not for new design" },
      },
      blocked: { text: { de: "Blocked", en: "Blocked" } },
      ltb: { text: { de: "LTB", en: "LTB" } },
      bsi: { text: { de: "BSI", en: "BSI" } },
      external: { text: { de: "External", en: "External" } },
    },
    search: false,
  },
  statusAlerts: {
    type: FieldTypes.value,
    text: {
      de: "Status Alert",
      en: "Status Alert",
      nl: "Status Alert",
    },
    search: false,
  },
  category: {
    type: FieldTypes.options,
    text: {
      de: "Kategorie",
      en: "Category",
      nl: "Category",
    },
    options: {
      a: { text: { de: "A", en: "A" } },
      b: { text: { de: "B", en: "B" } },
      c: { text: { de: "C", en: "C" } },
    },
    search: false,
  },
  activeMPNs: {
    type: FieldTypes.value,
    text: {
      de: "Aktive Hersteller",
      en: "Active Manufacturers",
      nl: "Number of active MPNs",
    },
    search: false,
  },
  verwa: {
    type: FieldTypes.options,
    text: {
      de: "VERWA",
      en: "VERWA",
      nl: "VERWA",
    },
    options: {
      1: { text: { de: "1", en: "1" } },
      2: { text: { de: "2", en: "2" } },
      3: { text: { de: "3", en: "3" } },
      4: { text: { de: "4", en: "4" } },
      5: { text: { de: "5", en: "5" } },
      6: { text: { de: "6", en: "6" } },
      7: { text: { de: "7", en: "7" } },
      T: { text: { de: "T", en: "T" } },
      8: { text: { de: "8", en: "8" } },
      9: { text: { de: "9", en: "9" } },
      D: { text: { de: "D", en: "D" } },
    },
    search: false,
  },
  create_time: {
    text: {
      de: "Erstellt am",
      en: "Created on",
      nl: "Gemaakt op",
    },
    type: FieldTypes.timestamp,
    search: false,
  },
  update_time: {
    text: {
      de: "Letzte Änderung am",
      en: "Last edited on",
      nl: "Laatst gewijzigd op",
    },
    type: FieldTypes.timestamp,
    search: false,
  },
};

const settings = {
  text: {
    part: { de: "Einheit", en: "Item", nl: "Item" },
    save: {
      de: "Änderungen übernehmen",
      en: "Save Changes",
      nl: "Bewaar de wijzigingen",
    },
    modalTitle: { de: "Bestätigung", en: "Confirm", nl: "Bevestigen" },
    edit: { de: "Bearbeiten", en: "Edit", nl: "Bewerk" },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard Changes",
      nl: "Wijzigingen negeren",
    },
    navigateBack: {
      de: "Zur vorherigen Einheit navigieren",
      en: "Navigate to previous Item",
      nl: "Navigeer naar vorig item",
    },
    close: { de: "Schließen", en: "Close", nl: "Sluit" },
    itemDetailSection: {
      de: "Item Details",
      en: "Item Details",
      nl: "Item Details",
    },
    internalDataSection: {
      de: "Interne Daten",
      en: "Internal Data",
      nl: "Interne Data",
    },
    riskAssessmentSection: {
      de: "Risiko Bewertung",
      en: "Risk Assessment",
      nl: "Risico Beoordeling",
    },
    billOfMaterialSection: {
      de: "Stückliste",
      en: "Bill of Materials",
      nl: "Stuklijst",
    },
    manufacturerSection: {
      de: "Hersteller",
      en: "Manufacturers",
      nl: "Fabrikanten",
    },
    history: { de: "Historie", en: "History", nl: "Historie" },
    savedSuccessfully: {
      de: "Erfolgreich gespeichert.",
      en: "Saved Successfully.",
      nl: "Succesvol opgeslagen.",
    },
    bomNoResults: {
      de: "Keine Ergebnisse gefunden",
      en: "No results found",
      nl: "Geen resultaten gevonden.",
    },
    removedBom: {
      de: "Die folgenden Artikel wurden aus der BOM entfernt:",
      en: "The following items have been removed from BOM:",
      nl: "De volgende items zijn verwijderd uit de BOM:",
    },
    whereUsed: { de: "Wo verwendet", en: "Where-used", nl: "Waar gebruikt?" },
    deleteParts: {
      de: "Artikel löschen",
      en: "Delete parts",
      nl: "Artikel verwijderen",
    },
    number: { de: "Nummer", en: "Number", nl: "Nummer" },
    image: { de: "Bild", en: "Image", nl: "Beeld" },
    details: { de: "KTN-Details", en: "CPN-Details", nl: "CPN-Details" },
    mpnDetails: { de: "HTN-Details", en: "MPN-Details", nl: "MPN-Details" },
    cpnDetails: {
      de: "Part Details",
      en: "Part Details",
      nl: "Part Details",
    },
    parts: { de: "Teile", en: "Parts", nl: "Artikelen" },
    partsList: {
      de: "Liste der Kundenteile",
      en: "List of parts",
      nl: "Lijst van artikelen",
    },
    partsWithoutMan: {
      de: "Kundenteile ohne Hersteller",
      en: "Parts without manufacturers",
      nl: "Artikelen zonder fabrikant",
    },
    partsNotUsed: {
      de: "Nicht in Baugruppen verwendete Kundenteile",
      en: "Parts not used in assemblies",
      nl: "Niet in assemblages gebruikte artikelen",
    },
    deletePartsView: {
      de: "Bauteile die gelöscht werden können",
      en: "Parts that can be deleted",
      nl: "Artikelen die kunnen worden verwijderd",
    },
    partsStatus: {
      de: "Bestandsüberwachung",
      en: "Inventory Monitoring",
      nl: "Inventory Monitoring",
    },
    inventoryMonitoringAlerts: {
      de: "Bestandsüberwachung Alarme",
      en: "Inventory Monitoring Alerts",
      nl: "Inventory Monitoring Alerts",
    },
    obsolescenceManagementShort: {
      de: "Proaktiv",
      en: "Proactive",
      nl: "Proactive",
    },
    deleteModal: {
      de: "Artikel aus der Stückliste entfernen",
      en: "Remove item from BOM",
      nl: "Item verwijderen uit BOM",
    },
    removePart: {
      de: "Sind Sie sicher, dass Sie den Artikel aus der Stückliste löschen möchten?",
      en: "Are you sure you want to remove items from BOM?",
      nl: "Weet u zeker dat u het items uit de BOM wilt verwijderen?",
    },
    deletePart: {
      de: "Sind Sie sicher, dass Sie den Artikel aus der Stückliste löschen möchten?",
      en: "Are you sure you want to delete items from BOM?",
      nl: "Weet u zeker dat u het items uit de BOM wilt verwijderen?",
    },
    remove: {
      de: "Entfernen",
      en: "Remove",
      nl: "Verwijderen",
    },
    createPart: {
      de: "Neuen Einheit hinzufügen",
      en: "Add new part",
      nl: "Nieuwe item toevoegen",
    },
    createdSuccesfully: {
      de: "Einheit erfolgreich erstellt",
      en: "Item created succesfully",
      nl: "Item met succes gemaakt",
    },
    partNumberExist: {
      de: "Die Teilenummer existiert bereits!",
      en: "Part Number already exist!",
      nl: "Nieuwe item toevoegen",
    },
    CPNWarning: {
      de: "Die von Ihnen gewählte CPN stimmt nicht mit der gewählten MPN überein!",
      en: "The CPN you have selected does not correspond with the selected MPN!",
      nl: "De door u geselecteerde CPN komt niet overeen met de geselecteerde MPN!",
    },
    addNewPart: {
      de: "Einheit zur Stückliste hinzufügen",
      en: "Add part to bill of materials",
      nl: "Add part to bill of materials",
    },
    quantityLabel: {
      de: "Menge",
      en: "Quantity",
      nl: "Aantal",
    },
    descriptionLabel: {
      de: "Bezeichnung der Einheit",
      en: "Designation of the Item",
      nl: "Artikelomschrijving",
    },
    currentStatusAlert: {
      de: "KTNs mit Unconfirmed Status unter diese Baugruppe",
      en: "CPNs with Unconfirmed Status under this Assembly",
      nl: "CPN's met onbevestigde status onder deze vergadering",
    },
    riskStatusAlert: {
      de: "KTNs mit Unknown Obsoleszenzrisiko unter diese Baugruppe",
      en: "CPNs with Unknown Obsolescence Risk under this Assembly",
      nl: "CPN's met onbekend risico van economische veroudering onder deze vergadering",
    },
    likelihoodStatusAlert: {
      de: "KTNs mit Unknown Obsoleszenzwahrscheinlichkeit unter diese Baugruppe",
      en: "CPNs with Unknown Obsolescence Likelihood under this Assembly",
      nl: "CPN's waarvan de waarschijnlijkheid van economische veroudering onbekend is onder deze Vergadering",
    },
    impactStatusAlert: {
      de: "KTNs mit Unknown Obsoleszenzauswirkung unter diese Baugruppe",
      en: "CPNs with Unknown Obsolescence Impact under this Assembly",
      nl: "CPN's met onbekende impact op economische veroudering onder deze vergadering",
    },
    rohsStatusAlert: {
      de: "KTNs mit Unconfirmed EU RoHS Status unter diese Baugruppe",
      en: "CPNs with Unknown EU RoHS Status under this Assembly",
      nl: "CPN's met onbekende EU-RoHS-status onder deze assemblage",
    },
    reachStatusAlert: {
      de: "KTNs mit Unknown REACH betroffen Status unter diese Baugruppe",
      en: "CPNs with Unknown REACH affected Status under this Assembly",
      nl: "CPN's met onbekende REACH-status onder deze vergadering",
    },
    leadTimeStatusAlert: {
      de: "KTNs mit Unknown Lieferzeiten Status unter diese Baugruppe",
      en: "CPNs with Unknown Lead Times Status under this Assembly",
      nl: "CPN's met onbekende doorlooptijd Status onder deze vergadering",
    },
    qty: {
      de: "Qty",
      en: "Qty",
      nl: "Qty",
    },
    sap: {
      de: "SAP",
      en: "SAP",
      nl: "SAP",
    },
    notApplicableAssembly: {
      de: "n/a -Baugruppe",
      en: "n/a -assembly",
      nl: "n/a -assembly",
    },
  },
  url: {
    defaultImage: "./assets/dummy.jpg",
  },
  field,
  list: {
    boxesLeft: empty,
    boxesLeftEditMode: empty,
    itemDetailSection: empty,
    internalDataSection: empty,
    riskAssessmentSection: empty,
    billOfMaterialSection: empty,
    manufacturerSection: empty,
    history: empty,
    csvExportPartColumns: empty,
    csvExportPartsListColumns: empty,
    csvExportBillOfMaterialColumns: empty,
    createPartFieldsList: empty,
  },
  permission: {
    edit: false,
    addItem: false,
    deleteItem: false,
  },
};

export const part: ComponentSettings<typeof settings> = settings;
