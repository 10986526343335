import { FilterHeaderTableServiceType } from "./filter-header-table.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import {
  filterAllManufacturers,
  filterManufacturersByCPNs,
  getPartFilterResult,
} from "../api.service";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { FilterPage } from "../filter-list/filter-list.state";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { SearchWords } from "../filter/filter.service.type";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class FilterHeaderTableService implements FilterHeaderTableServiceType {
  result: any = {};
  filterByPage: FilterPage[] = [];
  filters: FilterPage[] = [];
  filterValues: SearchWords = {};
  isFilterSelected: boolean = false;
  manufacturersListSearchResult: string[] = [];
  partsListSearchResult: string[] = [];
  nameSearchResult: string[] = [];
  MPNIds: string[] = [];
  resetAllFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  getFieldId(docType: string, columnName: string) {
    return docType + "." + columnName;
  }

  async filter(field: string, value: string) {
    if (StringUtils.isNullOrEmpty(value)) {
      return;
    }
    this.app.spinner.showSpinner();
    this.filterValues = this.createFilterObjectByView(field, value);

    if (this.app.type === "part") {
      await this.getPartSearchResult();
    }

    if (this.app.type === "manufacturer") {
      await this.getManufacturerSearchResult();
      // await Search(
      //   this.app.customers.expectCurrent,
      //   this.app.type,
      //   field.split(".")[1],
      //   value,
      //   true
      // );

      this.isFilterSelected = true;
    }

    this.app.spinner.hideSpinner();
    this.app.paginator.resetPagination();
  }

  async clearFilter(fieldId: string) {
    const propertyName = fieldId.split(".")[1];
    delete this.filterValues[propertyName];

    this.filterByPage = this.filterByPage.filter(
      (filter) => filter.filterLabelField !== fieldId
    );
    this.filters = this.getCurrentFilters();

    if (this.app.type === "manufacturer") {
      if (Object.keys(this.filterValues).length > 0) {
        this.getManufacturerSearchResult();
      } else if (this.app.RM.fromRMSearch) {
        await this.app.manufacturersFilter.getFilterResultBasedOnView("1");
        // await this.getOptionsBasedOnProperty(
        //   "manufacturer.manufacturerPartNumber"
        // );
      } else {
        await this.app.manufacturersFilter.getManufacturers(this.app.RM.mode);
        // await this.getOptionsBasedOnProperty(
        //   "manufacturer.manufacturerPartNumber"
        // );
      }
    } else if (this.app.type === "part") {
      if (Object.keys(this.filterValues).length > 0) {
        await this.getPartSearchResult();
      } else {
        this.app.partsTable.getFilterResultBasedOnView("1");
      }
      // await this.getOptionsBasedOnProperty("part.partNumber");
    }

    this.isFilterSelected = false;
    this.app.paginator.resetPagination();
  }

  createFilterObjectByView(fieldName: string, value: string) {
    var filter: FilterPage = {
      values: value,
      filterView: this.app.RM.mode as any,
      filterLabelField: fieldName,
    };

    // Columns of type: "value", "text" & "radio" could be filtered only by one value at a time
    if (
      this.app.field.getType(fieldName) === "value" ||
      this.app.field.getType(fieldName) === "text" ||
      this.app.field.getType(fieldName) === "radio"
    ) {
      this.removeInputDupFilter(fieldName);
    }

    let index = this.filterByPage.findIndex(
      (e) => e.filterLabelField === fieldName && e.values === value
    );

    if (index === -1) {
      this.filterByPage.push(filter);
    } else {
      this.filterByPage.splice(index, 1);
    }

    this.filters = this.getCurrentFilters();
    return this.app.filter.setFilterValues(this.filters);
  }

  getCurrentFilters() {
    this.filters = this.filterByPage.filter(
      (view: any) => view.filterView === this.app.RM.mode
    );
    return this.filters;
  }

  setFilterValues() {
    let values: {} = {};

    /** get all the filters that should be applied to the current view */
    var map = this.filters.reduce(
      (entryMap, e) =>
        entryMap.set(e.filterLabelField, [
          ...(entryMap.get(e.filterLabelField) || []),
          e,
        ]),
      new Map()
    );

    /** if there are any filters create query based on the type of the field */
    Array.from(map.keys()).forEach(async (key: string) => {
      if (key != null) {
        let splitKey = key.split(".")[1];
        if (map.get(key).length === 1) {
          if (
            this.app.filterTree.getFieldType(key) === "input" &&
            //manufacturer.name check is added only for testing purpose (mango index without regex)
            key !== "manufacturer.name"
          ) {
            (values as any)[splitKey] = {
              $regex: `(?i)${map.get(key)[0].values}`,
            };
          } else {
            (values as any)[splitKey] = map.get(key)[0].values;
          }
        } else {
          (values as any)[splitKey] = {};
          (values as any)[splitKey].$or = [
            ...map.get(key).map((v: FilterPage) => v.values),
          ];
        }
      }
    });
    this.filterValues = values;
    return values;
  }

  isChecked(field: string, option: string) {
    let filters = this.filters
      .filter((f: FilterPage) => f.filterLabelField === field)
      .map((v) => v.values);

    if (filters.length === 0) {
      return false;
    }

    if (filters.findIndex((o: string) => o === option) !== -1) {
      return true;
    }
    return false;
  }

  isSelected(field: string): boolean {
    if (
      this.filters.findIndex(
        (filter: FilterPage) => filter.filterLabelField === field
      ) !== -1
    ) {
      return true;
    }
    return false;
  }

  resetHeaderFilters() {
    this.filterValues = {};
    this.filters = [];
    this.filterByPage = [];
  }

  async getManufacturerSearchResult(page?: string) {
    let p = page != null ? page : "1";
    let mpnsByCPN: string[] = [];
    this.app.spinner.showSpinner();

    /** if the current view from RM search, try to get first all the MPNS contained inside the selected CPN  */
    if (this.app.RM.fromRMSearch) {
      const result = await filterManufacturersByCPNs(
        this.app.customers.expectCurrent,
        this.app.RM.mode,
        this.app.tree.partsOfSearchedAssembly,
        "all",
        "all"
      );
      mpnsByCPN = result.manufacturers.map((m) => m.manufacturerPartNumber);
    } else {
      mpnsByCPN = [];
    }

    /** get the list of manufacturers based on the previous list resulted */
    this.result = await filterAllManufacturers(
      this.app.customers.expectCurrent,
      Object.keys(this.filterValues),
      this.filterValues,
      this.app.RM.mode,
      p,
      this.app.paginator.pageSize.toString(),
      mpnsByCPN
    );
    this.MPNIds = this.result.mpnIds;
    this.app.manufacturersFilter.manufacturers = this.result.manufacturers;
    this.manufacturersListSearchResult = this.result.typeAheadOptions.mpns;
    this.partsListSearchResult = this.result.typeAheadOptions.cpns;

    this.app.manufacturersFilter.pages = this.result.size;
    this.app.manufacturersFilter.currentIds = this.result.manufacturers.map(
      (manufacturer: Manufacturer) => manufacturer._id
    );
    this.app.spinner.hideSpinner();
  }

  async getPartSearchResult(page?: string) {
    let p = page != null ? page : "1";
    this.app.spinner.showSpinner();

    this.result = await getPartFilterResult(
      this.app.customers.expectCurrent,
      Object.keys(this.filterValues),
      this.filterValues,
      this.app.RM.mode,
      p,
      this.app.paginator.pageSize.toString()
    );
    this.app.partsTable.parts = this.result.parts;
    this.app.partsTable.size = this.result.size;
    this.partsListSearchResult = this.result.typeAheadCPNs;

    this.app.spinner.hideSpinner();
  }

  // TODO: remove unused method
  // async getOptionsBasedOnProperty(fieldId: string) {
  //   switch (fieldId) {
  //     case "part.partNumber":
  //       //get partNumbers for parts tables based on rm mode
  //       await this.app.part.getListOfPartNumbers(this.app.RM.mode);
  //       this.partsListSearchResult = this.app.part.partNumbers;
  //       break;

  //     case "manufacturer.manufacturerPartNumber":
  //       //get partNumbers & manufacturerPartNumbers for manufactuers tables based on rm mode
  //       await this.app.manufacturer.getTypeaheadOptions(this.app.RM.mode);
  //       this.manufacturersListSearchResult =
  //         this.app.manufacturer.manufacturerPartNumbers;
  //       this.partsListSearchResult = this.app.part.partNumbers;
  //       break;
  //   }
  // }

  getFieldType(field: string) {
    const fieldType = this.app.field.getType(field);

    switch (fieldType) {
      case "text":
      case "date":
      case "number":
        return "value";
      case "radio":
        // Display the radio button using dropdowns, as the options field
        return "options";
      default:
        return fieldType;
    }
  }

  private removeInputDupFilter(fieldName: string) {
    const index = this.filterByPage.findIndex(
      (e) => e.filterLabelField === fieldName
    );

    if (index !== -1) {
      this.filterByPage.splice(index, 1);
    }
  }
}
