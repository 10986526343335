import * as XLSX from "xlsx";
import { AlertData } from "../../../../shared/models/alert";
import { AppType } from "../app.type";
import { Manufacturer, SVHCItem } from "../../../../shared/models/manufacturer";
import { Part } from "../../../../shared/models/part";
import { Post } from "../../../../shared/models/post";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { Task } from "../../../../shared/models/task";
import {
  filterManufacturersForExport,
  filterPartsForExport,
  getManufacturerIdsByMode,
  getManufacturers,
  getManufacturersByCPNs,
  getParts,
  getAllPartsByStatus,
  getPartsNotUsedInAssembliesAll,
  getPartsToBeDeletedAll,
  getPartsWithoutManufacturersAll,
} from "../api.service";
import { Customer } from "../../../../shared/types/customers";
import { COMMENT, SOLUTION, TASK } from "../post/post.service.type";
import { Docs, Type } from "../../../../shared/components";
import { formatDatetime } from "../utils/date.util";

export async function writeConfigFile(
  app: AppType,
  docType: string,
  exportType?: string
) {
  const { book, fileName } = await createBook(app, docType, exportType);
  XLSX.writeFile(book, fileName);
}

export async function createBook(
  app: AppType,
  docType: string,
  exportType?: string
) {
  const book = XLSX.utils.book_new();
  let name = "report.xlsx";
  switch (docType) {
    case "manufacturer":
      XLSX.utils.book_append_sheet(
        book,
        await getExportedManufacturers(app, exportType)
      );
      if (app.customers.expectCurrent === Customer.NS) {
        let assemblySelected = app.filterTree.assemblySelected;
        if (!StringUtils.isNullOrEmpty(assemblySelected)) {
          name = `${assemblySelected}.xlsx`;
        }
      }
      break;

    case "part":
      const part = app.part.part;
      let result: XLSX.WorkSheet;
      if (app.RM.isPart) {
        result = await getExportedParts(app, app.partsTable.parts);
      } else {
        result = await getExportedBillOfMaterial(app, exportType);
      }
      XLSX.utils.book_append_sheet(book, result);
      name = generateExportNameForParts(app, part);
      break;

    case "alert":
    case "changeAlert":
    case "leadTimeAlert":
    case "inventoryMonitoringAlert":
      XLSX.utils.book_append_sheet(book, await getExportedAlerts(app, docType));
      break;
    case TASK:
      XLSX.utils.book_append_sheet(book, await getExportedTasks(app));
      name = "report.xlsx";
      break;
    case "post":
      XLSX.utils.book_append_sheet(book, await getExportedPosts(app));
      name = "report.xlsx";
      break;
    case "user":
      XLSX.utils.book_append_sheet(book, await getExportedUsers(app));
      name = "report.xlsx";
      break;
  }
  return { book: book, fileName: name };
}

/** Prepare the RM data from bill of material section for export */
async function getExportedBillOfMaterial(app: AppType, exportType?: string) {
  const assemblies = app.part.currentAssemblies;
  let subPartsWithManufacturers: Part[] = [];
  let subPartsWithParts: Part[] = [];
  let result: XLSX.WorkSheet;
  assemblies.forEach((assembly) => {
    if (assembly.parts == null || Object.keys(assembly.parts).length == 0) {
      subPartsWithManufacturers.push(assembly);
    } else {
      subPartsWithParts.push(assembly);
    }
  });

  if (subPartsWithManufacturers.length > 0) {
    result = await getExportedBillOfMatWithManufacturers(
      app,
      subPartsWithManufacturers,
      subPartsWithParts,
      exportType
    );
  } else {
    result = await getExportedParts(app, assemblies);
  }
  return result;
}

/** Exports the RM data as seen in the manufacturers list views based on exportType */
async function getExportedManufacturers(app: AppType, exportType?: string) {
  let manufacturerIds: any[] = app.manufacturersFilter.currentIds;
  let manufacturers: Manufacturer[] = [];

  let assemblySelected = app.filterTree.assemblySelected;
  let filterSelected = app.filterHeaderTable.isFilterSelected;

  // if (Number(app.manufacturersFilter.pages) !== manufacturerIds.length) {
  if (StringUtils.isNullOrEmpty(assemblySelected)) {
    // if you are not on a mini tree table get the manufacturer ids based on the RM mode
    if (!filterSelected) {
      // if there is no selected filter on the table head
      manufacturerIds = await getManufacturerIdsByMode(
        app.customers.expectCurrent,
        app.RM.mode
      );
      manufacturers = await getManufacturers(
        app.customers.expectCurrent,
        manufacturerIds
      );
    }
    if (filterSelected) {
      // if there a filter selected on the table head
      manufacturerIds = app.filterHeaderTable.MPNIds;
      manufacturers = await getManufacturers(
        app.customers.expectCurrent,
        manufacturerIds
      );
    }
  }

  if (!StringUtils.isNullOrEmpty(assemblySelected)) {
    //if you are on a mini tree get the manufacturer ids from the already filtered manufacturers
    if (!filterSelected) {
      let allFilteredManufacturers = await filterManufacturersForExport(
        app.customers.expectCurrent,
        app.RM.mode,
        app.tree.partsOfSearchedAssembly
      );
      manufacturers = allFilteredManufacturers;
    }
    if (filterSelected) {
      // if there a filter selected on the table head
      manufacturerIds = app.filterHeaderTable.MPNIds;
      manufacturers = await getManufacturers(
        app.customers.expectCurrent,
        manufacturerIds
      );
    }
  }
  // }

  app.table.sort(manufacturers, "manufacturer");
  const partsIds = manufacturers.map((m) => m.partNumber);
  const parts = await getParts(app.customers.expectCurrent, partsIds);
  const { data, fields } = getManufacturerDataBasedOnExportType(
    app,
    manufacturers,
    parts,
    false,
    exportType
  );

  let sheet: {}[] = [];
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

/** Exports the RM data as seen in the bill of materials tables from a part */
async function getExportedParts(app: AppType, parts: Partial<Part>[]) {
  let fields: string[] = [];
  let sheet: {}[] = [];
  const data: {}[] = [];

  if (Number(app.partsTable.size) !== parts.length && app.view === "RM") {
    /** retrieve all data needed for the export if there is no filter applied on the list */
    parts = await getPartsBasedOnView(
      app,
      app.RM.mode,
      app.customers.expectCurrent
    );
  }

  if (app.RM.isPart) {
    fields = app.list.part.csvExportPartsListColumns;
  } else {
    fields = app.list.part.csvExportPartColumns;
  }

  if (
    app.customers.expectCurrent === Customer.MTU &&
    app.RM.mode == "parts-status"
  ) {
    fields = setMTUExportColumns(app);
  }
  app.table.sort(parts, "part");
  parts.forEach((part) => {
    data.push(fields.map((field) => getValues(app, field, part)));
  });
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

function setMTUExportColumns(app: AppType) {
  let specialColumns = [
    app.fieldId.part.actualStock,
    app.fieldId.part.stockPAS,
    app.fieldId.part.stockMS5,
    app.fieldId.part.stockPS2,
    app.fieldId.part.electronicalMat,
    "part.lastNote",
  ];

  specialColumns = [
    ...app.list.part.csvExportPartsListColumns,
    ...specialColumns,
  ];

  return specialColumns;
}

/** Exports the RM data as seen in the bill of materials from a part + manufacturer tables */
async function getExportedBillOfMatWithManufacturers(
  app: AppType,
  subPartsWithManufacturers: Part[],
  subPartsWithParts: Part[],
  exportType?: string
) {
  const subPartIds = subPartsWithManufacturers.map((p) => p.partNumber);
  const manufacturers = await getManufacturersByCPNs(
    app.customers.expectCurrent,
    subPartIds
  );

  const { data, fields } = getManufacturerDataBasedOnExportType(
    app,
    manufacturers,
    subPartsWithManufacturers,
    true,
    exportType
  );

  subPartsWithParts.forEach((part) => {
    data.push(fields.map((field) => getValues(app, field, part)));
  });

  let sheet: {}[] = [];
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

/** Get manufacturer data based on exportType */
function getManufacturerDataBasedOnExportType(
  app: AppType,
  manufacturers: Manufacturer[],
  parts: Part[],
  fromBillOfMatPage: boolean,
  exportType?: string
) {
  switch (exportType) {
    case "substances":
      let csvExportReachSubstancesFields =
        app.list.manufacturer.csvExportReachSubstances;

      // Remove quantity column if export is made from the manufacturers table
      if (!fromBillOfMatPage) {
        const index = app.list.manufacturer.csvExportReachSubstances.indexOf(
          app.fieldId.part.quantity
        );
        csvExportReachSubstancesFields.splice(index, 1);
      }
      return {
        data: getManufacturersSubstancesDetails(
          app,
          manufacturers,
          parts,
          csvExportReachSubstancesFields
        ),
        fields: csvExportReachSubstancesFields,
      };
    case "billOfMaterial":
      let csvExportBillOfMaterialColumns =
        app.list.part.csvExportBillOfMaterialColumns;

      return {
        data: getManufacturersGeneralDetails(
          app,
          manufacturers,
          parts,
          csvExportBillOfMaterialColumns
        ),
        fields: csvExportBillOfMaterialColumns,
      };
    default:
      let csvExportManufacturerColumns =
        app.list.manufacturer.csvExportManufacturerColumns;

      // Remove quantity column if export is made from the manufacturers table
      if (!fromBillOfMatPage) {
        const index =
          app.list.manufacturer.csvExportManufacturerColumns.indexOf(
            app.fieldId.part.quantity
          );
        csvExportManufacturerColumns.splice(index, 1);
      }
      return {
        data: getManufacturersGeneralDetails(
          app,
          manufacturers,
          parts,
          csvExportManufacturerColumns
        ),
        fields: csvExportManufacturerColumns,
      };
  }
}

/** Special Case. When a file has been manually uploaded to the conformityCertificate field */
function changeValueOfTheField(app: AppType, manufacturer: Manufacturer) {
  if (manufacturer.attachmentHistory) {
    const files = manufacturer.attachmentHistory;
    if (files == null) {
      return;
    }
    const fileNames = Object.keys(files);
    fileNames.forEach((name) => {
      const fieldId = files[name].fieldId;
      if (
        fieldId === app.fieldId.manufacturer.conformityCertificate.split(".")[1]
      ) {
        manufacturer.conformityCertificate =
          app.text.manufacturer.manuallyUpload;
      }
    });
  }
  return manufacturer;
}

function getManufacturersGeneralDetails(
  app: AppType,
  manufacturers: Manufacturer[],
  parts: Part[],
  fields: string[]
) {
  let data: {}[] = [];

  manufacturers.forEach((manufacturer) => {
    const part = parts.find((p) => p._id === manufacturer.partNumber);

    if (part == null) {
      return;
    }

    if (manufacturer["obsolescenceStatusOverride"]) {
      const newManufacturer = changeValueOfTheField(app, manufacturer);
      if (newManufacturer == null) {
        return;
      } else {
        manufacturer = newManufacturer;
      }
    }
    data.push(
      fields.map((field) =>
        getValues(
          app,
          field,
          field.split(".")[0] === "manufacturer" ? manufacturer : part
        )
      )
    );
  });
  return data;
}

function getManufacturersSubstancesDetails(
  app: AppType,
  manufacturers: Manufacturer[],
  parts: Part[],
  fields: string[]
) {
  let data: {}[] = [];

  manufacturers.forEach((manufacturer) => {
    const part = parts.find((p) => p._id === manufacturer.partNumber);

    if (part == null) {
      return;
    }

    let svhcItems = manufacturer.svhcItems;
    if (
      svhcItems == null ||
      (typeof svhcItems === "string" && svhcItems === "")
    ) {
      svhcItems = [];
    } else {
      svhcItems = manufacturer.svhcItems;
    }

    /** check added only because the svhcItems could be undefined | "" | [] | SvhcItem[] */
    // if (
    //   (svhcItems &&
    //     svhcItems.length > 0 &&
    //     svhcItems[0].concentration === "") ||
    //   (svhcItems as any).length === 0 ||
    //   !svhcItems
    // ) {
    //   return;
    // }

    if (svhcItems && svhcItems.length > 0) {
      svhcItems.forEach((item: SVHCItem) => {
        data.push(
          fields.map((field) => {
            if (
              field === "manufacturer.substanceIdentification" ||
              field === "manufacturer.casNumber" ||
              field === "manufacturer.concentration" ||
              field === "manufacturer.thresholdLimitExceeded"
            ) {
              return getValueOfTheField(field, item);
            } else {
              return getValues(
                app,
                field,
                field.split(".")[0] === "manufacturer" ? manufacturer : part
              );
            }
          })
        );
      });
    } else {
      data.push(
        fields.map((field) =>
          getValues(
            app,
            field,
            field.split(".")[0] === "manufacturer" ? manufacturer : part
          )
        )
      );
    }
  });
  return data;
}

async function getExportedAlerts(app: AppType, docType: string) {
  let sheet: {}[] = [];
  const { data, fields } = getExportedAlertsData(app, docType);
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

function getExportedAlertsData(app: AppType, docType: string) {
  let csvColumns: string[] = [];
  switch (docType) {
    case "changeAlert":
      csvColumns = app.list.alert.changeAlertsCsvExportColumns;
      break;
    case "leadTimeAlert":
      csvColumns = app.list.alert.leadTimeAlertCsvExportColumns;
      break;
    case "inventoryMonitoringAlert":
      csvColumns = app.list.alert.invMonAlertCsvExportColumns;
      break;
    default:
      csvColumns = app.list.alert.alertsCsvExportColumns;
      break;
  }
  return {
    data: getExportedAlertsDetails(app, app.alerts.allAlerts, csvColumns),
    fields: csvColumns,
  };
}

function getExportedAlertsDetails(
  app: AppType,
  alerts: AlertData[],
  fields: string[]
) {
  let data: {}[] = [];
  alerts.forEach((alert: AlertData) => {
    data.push(fields.map((field) => getValues(app, field, alert)));
  });
  return data;
}

async function getExportedTasks(app: AppType) {
  let sheet: {}[] = [];
  const { data, fields } = getExportedTasksData(app);
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

function getExportedTasksData(app: AppType) {
  let csvColumns: string[] = [];
  csvColumns = app.list.post.tasksCsvExportColumns;
  return {
    data: getExportedTasksDetails(app, app.tasks.tasks, csvColumns),
    fields: csvColumns,
  };
}

function getExportedTasksDetails(
  app: AppType,
  tasks: Task[],
  fields: string[]
) {
  let data: {}[] = [];
  tasks.forEach((alert: Task) => {
    data.push(fields.map((field) => getValues(app, field, alert)));
  });
  return data;
}

async function getExportedPosts(app: AppType) {
  let sheet: {}[] = [];
  const { data, fields } = getExportedPostsData(app);
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

function getExportedPostsData(app: AppType) {
  let csvColumns: string[] = [];
  const clonePosts: Post[] = [];

  app.post.posts.forEach((post) => clonePosts.push(Object.assign({}, post)));
  csvColumns = app.list.post.postsCsvExportColumns;

  return {
    data: getExportedPostsDetails(app, clonePosts, csvColumns),
    fields: csvColumns,
  };
}

function getExportedPostsDetails(
  app: AppType,
  posts: Post[],
  fields: string[]
) {
  let data: {}[] = [];

  posts.forEach((post: Post) => {
    if (post.typeOfPost === SOLUTION && post.resolveClass != undefined) {
      post.title_comment = app.field.getFieldValueAsText(
        "post.resolveClass",
        post.resolveClass
      );
    }

    if (!post.hasOwnProperty("typeOfPost")) {
      post.typeOfPost = COMMENT;
    }

    if (post.typeOfPost != undefined) {
      post.typeOfPost =
        post.typeOfPost[0].toUpperCase() + post.typeOfPost.slice(1);
    }

    if (post.snapshot != undefined && post.snapshot.thread != undefined) {
      post.omfStatus = post.snapshot.thread.omfStatus;
    }

    data.push(fields.map((field) => getValues(app, field, post)));
  });
  return data;
}

async function getExportedUsers(app: AppType) {
  let sheet: {}[] = [];
  const { data, fields } = getExportedUsersData(app);
  sheet = [fields.map((field) => getLabel(app, field)), ...data];
  let worksheet = XLSX.utils.json_to_sheet(sheet, {
    skipHeader: true,
    dateNF: "yyyy-mm-dd, hh:mm:ss",
  });

  // set width cell in "characters"
  const columnWidth = 20;
  const wscols = data[0]
    ? Object.keys(data[0]).map(() => ({ wch: columnWidth }))
    : [];
  worksheet["!cols"] = wscols;

  return worksheet;
}

function getExportedUsersData(app: AppType) {
  let csvColumns: string[] = [];
  csvColumns = app.list.user.usersCsvExportColumns;
  if (app.customers.expectCurrent === Customer.NS) {
    if (app.auth.isTeam) {
      csvColumns = csvColumns.filter((x) => x !== "user.passwordExpireDate");
    } else {
      csvColumns = csvColumns.filter((x) => x !== "user.jobTitle");
    }
  }

  return {
    data: getExportedUsersDetails(app, app.users.users, csvColumns),
    fields: csvColumns,
  };
}

function getExportedUsersDetails(
  app: AppType,
  users: Docs[Type][],
  fields: string[]
) {
  let data: {}[] = [];
  users.forEach((user: any) => {
    let userCopy = Object.assign({}, user);
    if (userCopy.active) {
      userCopy.active = "Active";
    } else {
      userCopy.active = "Offline";
    }

    if (userCopy.roles != null) {
      userCopy.roles.forEach((role: string) => {
        let userRole = role.split("-")[1];
        let userRoleText = app.field.getOptionText("user.roles", userRole);
        userCopy.roles = [userRoleText];
      });
    }

    if (app.customers.expectCurrent === Customer.NS) {
      if (app.auth.isTeam) {
        fields = fields.filter((x) => x !== "user.passwordExpireDate");
      } else {
        fields = fields.filter((x) => x !== "user.jobTitle");
      }
    }

    if (app.customers.expectCurrent === Customer.NS && app.auth.isTeam) {
    }
    data.push(fields.map((field) => getValues(app, field, userCopy)));
  });
  return data;
}

function getValueOfTheField(field: string, item: SVHCItem) {
  let result = "";
  field = field.split(".")[1];
  switch (field) {
    case "substanceIdentification":
    case "casNumber":
    case "concentration":
      result = item[field] as string;
      break;
    case "thresholdLimitExceeded":
      if (item.concentration === "") {
        result = "";
      } else {
        result = item[field] === true ? "Yes" : "No";
      }
      break;
  }
  return result;
}

function getLabel(app: AppType, field: string) {
  const fieldType = field.split(".")[0];
  switch (fieldType) {
    case "part":
      // used for MTU Inventory Monitoring export
      if (field === "part.lastNote") {
        return `CPN ${app.text.message.message}`;
      } else {
        return `CPN ${app.field.getLabel(field)}`;
      }
    case "manufacturer":
      return app.field.getTableLabel(field);
    default:
      return app.field.getLabel(field);
  }
}

function getValues(app: AppType, fieldId: string, doc: any) {
  const [type, field] = fieldId.split(".");
  if (doc != null && doc[field] != null && doc[field] != "null") {
    //special case since same fields exists on the part and also on the manufacturer side
    if (type === "manufacturer" && doc.type === "part") {
      return "";
    } else {
      if (fieldId === "part.lastNote") {
        return doc[field];
      } else {
        const fieldSettings = app.field.getFieldSettings(fieldId);

        if (
          fieldSettings.type === "date" ||
          field === "lastUpdatedOn" ||
          field === "effectiveDate" ||
          field === "create_time" ||
          field === "issueDate" ||
          field === "itemEOP"
        ) {
          if (!StringUtils.isNullOrEmpty(doc[field])) {
            let date = new Date(formatDatetime(doc[field]));
            return date;
          } else {
            return "";
          }
        }

        return app.field.getFieldValueAsText(fieldId, doc[field]);
      }
    }
  } else {
    return "";
  }
}

function generateExportNameForParts(app: AppType, part: Part) {
  let name = "report.xlsx";

  if (!app.RM.isPart) {
    const partNumber = part[app.fieldId.part.partNumber];
    const partDescr = part[app.fieldId.part.description];

    if (partDescr != null) {
      name = `${partNumber}_${partDescr}.xlsx`;
    } else {
      name = `${partNumber}.xlsx`;
    }
  } else if (app.customers.expectCurrent === Customer.NS) {
    const partNumber = app.model[app.fieldId.part.partNumber];
    if (!StringUtils.isNullOrEmpty(partNumber)) {
      name = `${partNumber}.xlsx`;
    }
  }
  return name;
}

async function getPartsBasedOnView(
  app: AppType,
  view: string,
  customer: string
) {
  switch (view) {
    case "parts-to-be-deleted":
      return await getPartsToBeDeletedAll(customer);
    case "without-manufacturers":
      return await getPartsWithoutManufacturersAll(customer);
    case "not-used-assemblies":
      return await getPartsNotUsedInAssembliesAll(customer);
    case "parts-status":
      return await getAllPartsByStatus(customer);
    case "all-parts":
      // let result = await getPartsCompressed(customer);
      let result = await filterPartsForExport(
        app.customers.expectCurrent,
        Object.keys(app.filterHeaderTable.filterValues),
        app.filterHeaderTable.filterValues,
        app.RM.mode
      );
      return result;
    default:
      return [];
  }
}
