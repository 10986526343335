import { Component, OnInit, ViewChild } from "@angular/core";
import { DocModel } from "../state/state";
import { getApp } from "../app";
import { LoginDetails } from "../../../../shared/models/login-details";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  @ViewChild("resetPasswordModal", { static: true })
  resetPasswordModal: any;

  app = getApp((app) => {
    this.app = app;
  });

  model: DocModel = {};

  constructor() {}

  async ngOnInit() {
    await this.checkAuth();

    // try {
    // } catch (err) {
    //   // simple login without credentials didn't work -> user has no valid cookie and needs to authenticate manually
    //   this.app.auth.prefillLoginForm(this.model);
    // }
  }

  get details(): LoginDetails {
    return {
      user: this.model[this.app.fieldId.user.name],
      password: this.model[this.app.fieldId.user.password],
    };
  }

  // hasError - used to display the error
  // errorLoginName, errorLoginPassword, loginError - used for type of the error
  // errorText - used to set the error message

  async checkAuth(): Promise<void> {
    const authenticated = await this.app.auth.checkAuth();

    if (authenticated && this.app.customer !== null) {
      this.app.routing.navigate(this.app.customer, "home");
    }
  }

  async attemptLogin(): Promise<void> {
    this.app.hasError = false;
    this.app.loginError = false;
    this.app.errorText = "";

    try {
      if (StringUtils.isNullOrEmpty(this.details.user)) {
        this.app.hasError = true;
        this.app.errorLoginName = true;
        this.app.errorText = this.app.text.auth.loginName;
        return;
      } else if (StringUtils.isNullOrEmpty(this.details.password)) {
        this.app.hasError = true;
        this.app.errorLoginPassword = true;
        this.app.errorText = this.app.text.auth.loginPassword;
        return;
      }

      await this.app.auth.login(this.details);
    } catch (err) {
      this.app.hasError = true;
      this.app.loginError = true;
      this.app.errorText = this.app.text.auth.loginError;
    }
  }

  getContactFormURL() {
    let link = "";
    if (this.app.auth.landing != null) {
      switch (this.app.language) {
        case "de":
          link = "https://www.obsolescence-management.net/#contact";
          break;
        case "en":
          link = "https://www.obsolescence-management.net/en/#contact";
          break;
      }
    } else {
      switch (this.app.language) {
        case "de":
          link = "https://lcm-client.com/de/#contact";
          break;
        case "en":
          link = "https://lcm-client.com/#contact";
          break;
      }
    }
    return link;
  }

  openModal() {
    this.resetPasswordModal.open({ backdrop: "static", keyboard: false });
  }
}
