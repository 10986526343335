import { Inject, Injectable } from "@angular/core";
import { APP_TYPE, AppType } from "../app.type";
import { SyncServiceType } from "./sync.service.type";
import { Type } from "../../../../shared/components";
import {
  updateSyncDoc,
  downloadFile,
  uploadFile,
  getSyncDoc,
  getSyncDocClient,
  savePost,
} from "../api.service";
import { LinkedOmfNumbers, SyncDoc } from "../../../../shared/models/sync";
import { Post } from "../../../../shared/models/post";
import { Thread } from "../../../../shared/models/thread";
import { Customer } from "../../../../shared/types/customers";
import { CustomerName } from "../../../../shared/config/customers";

export class SyncInfo {
  syncedFromCustomer: CustomerName | null = null;
  syncedFile: File[] = [];
  syncedAttachmentsInfo: any[] = [];
  syncedDoc: any;
}

@Injectable()
export class SyncService implements SyncServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  syncDoc: any = {} as SyncDoc;
  syncInfo: SyncInfo = {
    syncedFromCustomer: null,
    syncedFile: [],
    syncedAttachmentsInfo: [],
    syncedDoc: {},
  };

  async prepareSyncInfo(
    type: Type,
    id: string,
    syncCustomer: string,
    currentCustomer = this.app.state.customer
  ) {
    if (currentCustomer == null || id == null) {
      return;
    }

    await this.getSyncInfo(id, type, currentCustomer);

    if (type === "thread") {
      //redirect to the new thread page of the destination(client where the data is going to be copied)
      this.app.routing.navigateNewThread(syncCustomer);
    }
    if (type === "post") {
      this.sync(type, id, syncCustomer, currentCustomer);
    }
  }

  async sync(
    type: Type,
    id: string,
    syncCustomer: string,
    currentCustomer = this.app.state.customer
  ) {
    this.app.spinner.showSpinner();
    const currentThread: Thread = this.app.thread.thread;
    let currentThreadId = currentThread["thread.omfNumber"];

    if (currentCustomer == null) {
      return;
    }

    //create/update syncDoc depending on the type
    if (type === "thread") {
      await this.addThreadIdToSyncDoc(
        id,
        currentThreadId,
        currentCustomer,
        syncCustomer
      );
    }
    if (type === "post") {
      await this.syncPost(id, syncCustomer, currentCustomer);
      await this.addPostIdToSyncDoc(
        id,
        currentCustomer,
        currentThreadId,
        syncCustomer
      );
    }

    //prepare sync attachments to be upload
    const { syncedFile, syncedAttachmentsInfo } = this.syncInfo;
    for (let i = 0; i < syncedAttachmentsInfo.length; i++) {
      const fileLink = [
        syncCustomer,
        type,
        type == "thread" ? btoa(currentThread["thread._id"]) : id,
        syncedAttachmentsInfo[i],
      ].join("/");
      await uploadFile(syncedFile[i], fileLink);
    }

    // empty the syncInfo
    this.clearTheSyncInfo();

    // get sync docs
    if (this.app.customers.expectCurrent === Customer.OMP) {
      await this.app.sync.getOmpSyncDoc(currentThreadId);
    } else if (currentCustomer !== null) {
      await this.app.sync.getClientSyncDoc(currentCustomer, currentThreadId);
    }
  }

  isSynced(type: string, id: string, syncCustomer: string) {
    if (this.syncDoc.length > 0) {
      // check if current type is synced
      switch (type) {
        case "thread":
          if (this.app.customers.expectCurrent !== Customer.OMP) {
            return true;
          } else {
            return this.isIdSynced(type, syncCustomer);
          }
        case "post":
          if (this.app.customers.expectCurrent !== Customer.OMP) {
            return this.isIdSynced(type, this.app.customers.expectCurrent, id);
          } else {
            return this.isIdSynced(type, syncCustomer, id);
          }
        default:
          return false;
      }
    }
    return false;
  }

  async getOmpSyncDoc(id: string) {
    this.syncDoc = await getSyncDoc(id);
  }

  async getClientSyncDoc(customer: string, id: string) {
    this.syncDoc = await getSyncDocClient(customer, id);
  }

  clearTheSyncInfo() {
    this.syncInfo = {
      syncedFromCustomer: null,
      syncedFile: [],
      syncedAttachmentsInfo: [],
      syncedDoc: {},
    };
  }

  private async getSyncInfo(
    id: string,
    type: Type,
    currentCustomer: CustomerName
  ) {
    //get the attachments of the type(case/post) which we are going to duplicate

    const attachments = await this.app.file.getAttachmentsByDocType(
      id,
      type,
      currentCustomer
    );

    const attachmentsFile: File[] = [];
    const attachmentsInfo: any[] = [];
    if (attachments.length !== 0) {
      let entries = Object.entries(attachments);
      for (let i = 0; i < entries.length; i++) {
        const fileName = entries[i][0];
        const fileLink = [currentCustomer, type, btoa(id), fileName].join("/");

        const data = await downloadFile(fileLink);
        const file = new File([data], fileName, {
          type: entries[i][1].content_type || "",
        });
        attachmentsFile.push(file);
        attachmentsInfo.push(entries[i][0]);
      }
    }

    //save the info of the type(case/post) which we are going to duplicate
    this.syncInfo = {
      syncedFromCustomer: currentCustomer,
      syncedFile: attachmentsFile,
      syncedAttachmentsInfo: attachmentsInfo,
      syncedDoc: this.app.thread.thread,
    };
  }

  private async syncPost(
    id: string,
    syncCustomer: string,
    currentCustomer: string | null
  ) {
    // get sync doc for current id
    let currentPost: any = {} as Post;
    this.app.post.posts.forEach((post) => {
      if (post._id === id) {
        currentPost = post;
      }
    });

    let currentPostCopy = Object.assign(currentPost);
    if (currentCustomer === Customer.OMP || currentCustomer === Customer.NS) {
      currentPostCopy.resolveClass = this.matchSolutionType(
        currentPostCopy.resolveClass
      );
    }
    //delete fields that we do not need to sync
    delete currentPostCopy._rev;
    delete currentPostCopy._attachments;

    //replace the post omfNumber with the destination client thread omfNumber
    if (currentCustomer !== Customer.OMP) {
      currentPostCopy.omfNumber = this.syncDoc[0].omfNumber;
      delete currentPostCopy.acceptedSolution;
      delete currentPostCopy.solutionImpacts;
    } else {
      let linkedOmfNumbers = this.syncDoc[0].linkedOmfNumbers;
      linkedOmfNumbers.forEach((element: LinkedOmfNumbers) => {
        if (element.customer === syncCustomer) {
          currentPostCopy.omfNumber = element.omfNumber;
        }
      });
    }

    await savePost(syncCustomer, currentPostCopy, true);
    this.app.routing.reloadCurrentPage(currentCustomer as CustomerName);
  }

  private async addThreadIdToSyncDoc(
    id: string,
    newId: string,
    currentCustomer: string,
    syncCustomer: string
  ) {
    if (this.syncDoc.length > 0) {
      // update syncDoc
      for (let i = 0; i < this.syncDoc.length; i++) {
        if (this.syncDoc[i].omfNumber === id) {
          this.syncDoc[i].linkedOmfNumbers.push({
            customer: syncCustomer,
            omfNumber: newId,
            linkedPosts: [],
          });
        }
      }
      await updateSyncDoc({ ...this.syncDoc[0] });
    } else {
      // create a new syncDoc
      await this.createSyncDocument(
        "thread",
        syncCustomer,
        currentCustomer,
        id,
        newId
      );
    }
  }

  private async addPostIdToSyncDoc(
    id: string,
    currentCustomer: string,
    currentThreadId: string,
    syncCustomer: string
  ) {
    for (let i = 0; i < this.syncDoc.length; i++) {
      const linkedOmfs = this.syncDoc[i].linkedOmfNumbers;
      for (let j = 0; j < linkedOmfs.length; j++) {
        if (
          linkedOmfs[j].omfNumber === currentThreadId &&
          currentCustomer !== Customer.OMP
        ) {
          linkedOmfs[j].linkedPosts.push(id);
        }
        if (
          linkedOmfs[j].customer === syncCustomer &&
          currentCustomer === Customer.OMP
        ) {
          linkedOmfs[j].linkedPosts.push(id);
        }
      }
    }
    await updateSyncDoc({ ...this.syncDoc[0] });
  }

  private async createSyncDocument(
    type: string,
    syncCustomer: string,
    currentCustomer: string | null,
    id: string,
    newId: string
  ) {
    // create sync documnet depending on the type
    if (type === "thread") {
      if (currentCustomer === Customer.OMP) {
        const newSyncDoc = {
          type: "sync",
          omfNumber: id,
          linkedOmfNumbers: [
            {
              customer: syncCustomer,
              omfNumber: newId,
              linkedPosts: [],
            },
          ],
        };
        await updateSyncDoc(newSyncDoc);
      } else {
        const newSyncDoc = {
          type: "sync",
          omfNumber: newId,
          linkedOmfNumbers: [
            {
              customer: currentCustomer,
              omfNumber: id,
              linkedPosts: [],
            },
          ],
        };
        await updateSyncDoc(newSyncDoc);
      }
    }
  }

  private isIdSynced(type: string, customer: string, id?: string) {
    const linkedOmfs = this.syncDoc[0].linkedOmfNumbers;
    for (let i = 0; i < linkedOmfs.length; i++) {
      if (linkedOmfs[i].customer === customer) {
        if (type === "thread") {
          return true;
        }
        if (linkedOmfs[i].linkedPosts.includes(id) && type === "post") {
          return true;
        }
      }
    }
    return false;
  }

  private matchSolutionType(solution: string) {
    switch (solution) {
      case "reverse_engineering_physical_prod":
      case "higher_level_fff_prod_lvl":
        return "Redesign";
      case "Redesign":
        return "reverse_engineering_physical_prod";
      case "update_config":
        return "Alternativer Lieferant";
      case "Alternativer Lieferant":
      case "Nichts tun":
        return "update_config";
      case "Existing Stock":
        return "same_item_cannibalisation";
      case "life_of_need_buy":
        return "LTB-Last Time Buy";
      case "LTB-Last Time Buy":
        return "life_of_need_buy";
      case "higher_level_not_fff_prod_lvl":
        return "Preservation";
      case "same_item_cannibalisation":
        return "Kannibalisierung";
      case "Kannibalisierung":
        return "same_item_cannibalisation";
      case "same_item_repair":
        return "Instandsetzung";
      case "Instandsetzung":
        return "same_item_repair";
      case "substitute_not_fff_level":
      case "substitute_fff_level":
        return "Alternative";
      case "Alternative":
        return "substitute_not_fff_level";
      case "same_item_no_obs":
        return "Nichts tun";
      case "Eigenfertigung":
      case "Preservation":
        return "same_item_no_obs";
    }
  }
}
