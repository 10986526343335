import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Part } from "../../../../shared/models/part";
import { getApp } from "../app";
import {
  ASSEMBLY,
  MANUFACTURER,
  PART,
  RMSectionType,
} from "./rm-search.service";
@Component({
  selector: "app-rm",
  templateUrl: "./rm-search.component.html",
  styleUrls: ["./rm-search.component.scss"],
})
export class RMSearchComponent implements OnInit, OnDestroy {
  assembly: RMSectionType = ASSEMBLY;
  part: RMSectionType = PART;
  manufacturer: RMSectionType = MANUFACTURER;
  types = ["assembly", "part", "manufacturer"];
  properties: string[] = [];
  badge: string = "badge";
  option: string = "option";
  model = {} as Part;

  private _routeSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.setType("assembly");
    this.getPropertiesByType(this.app.RMSearch.selectedType);
    this.app.RMSearch.filterMode = this.app.fieldId.part.partNumber;
    this.app.RMSearch.getRMSearchColumns();
    this.app.type = "rm.search";

    // Reset filter when access the rm-page, in order to delete the previous result if there is any
    this.app.RMSearch.resetFilterMode();

    this._routeSubscription = this.route.data.subscribe((data) => {
      this.app.RM.displayRMHeader = data.displayRMHeader;
    });

    this.app.RMSearch.getOptionsBasedOnProperty();
  }

  getPropertiesByType(type: string) {
    switch (type) {
      case "part":
      case "assembly":
        this.properties = this.app.list.RM.rmSearchPartDropdown;
        this.app.RMSearch.setFilterMode(this.app.fieldId.part.partNumber);
        break;
      case "manufacturer":
        this.properties = this.app.list.RM.rmSearchManufacturerDropdown;
        this.app.RMSearch.setFilterMode(
          this.app.fieldId.manufacturer.manufacturerPartNumber
        );
        break;
    }
  }

  getTextDisplay(value: string) {
    switch (value) {
      case "part":
        return this.app.text.part.part;
      case "manufacturer":
        return this.app.field.getLabel("alert.name");
      case "assembly":
        return this.app.text.import.structure;
    }
  }

  setType(type: RMSectionType) {
    this.app.RMSearch.model = "";
    this.app.RMSearch.selectedType = type;
    this.app.RMSearch.setFilterMode(this.app.RMSearch.filterMode);
    this.getPropertiesByType(type);
    this.app.RMSearch.getOptionsBasedOnProperty();
    this.app.RMSearch.getRMSearchColumns();
    this.app.RMSearch.clearField.next(true);
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    this.app.RMSearch.clearField.next(false);
  }
}
