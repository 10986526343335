import { FilterTableServiceType } from "./filter-table.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { Part } from "../../../../shared/models/part";
import { formatDatetime } from "../utils/date.util";
import { Customer } from "../../../../shared/types/customers";
import { WITHOUT_DATA } from "../filter-tree/filter-tree.service";
import { BehaviorSubject } from "rxjs";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { TASK } from "../post/post.service.type";
@Injectable()
export class FilterTableService implements FilterTableServiceType {
  resetAllFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isDropdownOpen: boolean = false;
  filterFocus: boolean = false;
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  fieldsWithEmptyOption = [
    this.app.fieldId.manufacturer.obsolescenceStatus,
    this.app.fieldId.manufacturer.obsolescenceStatus2years,
    this.app.fieldId.manufacturer.obsolescenceStatus4years,
    this.app.fieldId.manufacturer.obsolescenceStatus6years,
    this.app.fieldId.manufacturer.obsolescenceStatus8years,
    this.app.fieldId.manufacturer.likelihood,
    this.app.fieldId.manufacturer.euRoHS,
    this.app.fieldId.manufacturer.reachAffected,
    this.app.fieldId.manufacturer.leadTime,
    this.app.fieldId.manufacturer.euRoHSStatus,
    this.app.fieldId.part.obsolescenceStatus,
    this.app.fieldId.manufacturer.lifecycleCode,
  ];

  getFieldId(docType: string, columnName: string) {
    const fieldId = docType + "." + columnName;
    switch (fieldId) {
      case "manufacturer.Lifecycle":
        return this.app.fieldId.manufacturer.obsolescenceStatus;
      case "manufacturer.RoHS":
        return this.app.fieldId.manufacturer.euRoHS;
      default:
        return fieldId;
    }
  }

  filterDocsByFieldType(
    docType: string,
    docs: any[],
    filterValue: string,
    filterColumnName: string
  ) {
    const fieldId = this.getFieldId(docType, filterColumnName);

    const fieldType = this.getFilterType(fieldId);
    let result: any[] = [];

    switch (fieldType) {
      case "options":
        result = this.filterOptionValueAsText(
          docs,
          fieldId,
          filterColumnName,
          filterValue
        );
        break;
      case "timestamp":
        result = this.filterTimestampValueAsText(
          docs,
          filterColumnName,
          filterValue
        );
        break;
      default:
        if (filterValue !== "") {
          result = docs.filter(
            (element) =>
              element[filterColumnName] != null &&
              String(element[filterColumnName])
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1
          );
        } else {
          result = docs;
        }
        break;
    }
    return result;
  }

  getFilterType(fieldId: string) {
    switch (fieldId) {
      //special fields that we would like to treat them as value
      case this.app.fieldId.alert.severity:
      case this.app.fieldId.post.taskResponsible:
        return "value";
      default:
        return this.app.field.getType(fieldId);
    }
  }

  getOptionsAsValue(column: string) {
    switch (column) {
      case "creator_class":
        return true;
      default:
        return false;
    }
  }

  getFilterResults(docs: any[], type: string) {
    switch (type) {
      case "alert":
        this.app.alerts.allAlerts = docs;
        break;
      case "change":
        this.app.change.changes = docs;
        break;
      case "detailTable":
        this.app.detailTable.docs = docs;
        break;
      case "manufacturer":
        if (this.app.type === "pcnData") {
          /** exception added for pcnData type -> pcnHistory
           * is not a class, is a property of manufacturer type
           */
          this.app.manufacturerUtils.pcnHistoryDocs = docs;
          this.app.manufacturerUtils.pcnIds = docs.map((doc: any) => doc._id);
        } else if (
          this.app.leftNav.selectedBox === "manufacturer.supplyChainSection"
        ) {
          this.app.manufacturerUtils.currentInvDocs = docs;
        } else {
          this.app.manufacturersFilter.manufacturers = docs;
        }
        break;
      case "part":
        this.app.partsTable.parts = docs;
        break;
      case "pcn":
        this.app.pcns.pcns = docs;
        break;
      case "replacements":
        this.app.manufacturerUtils.substitutes = docs;
        break;
      case "seMatch":
        this.app.manufacturerMatching.manufacturer.matches = [];
        this.app.manufacturerMatching.manufacturer.matches = docs;
        break;
      case TASK:
        this.app.tasks.tasks = docs;
        break;
      case "users":
        this.app.users.users = docs;
        break;
      case "personProfile":
        this.app.users.users = docs;
        break;
      case "vehicleResponsible":
        if (this.app.customers.expectCurrent === Customer.DB) {
          let docIds = docs.map((doc: any) => doc._id);
          this.app.vehicleResponsible.ids = docIds;
        }
        break;
      case "impact":
        if (this.app.customers.expectCurrent === Customer.DB) {
          this.app.impacts.impactIds = docs.map((doc: any) => doc._id);
        }
        break;
    }
  }

  getFilterTableColumns(column: string) {
    switch (column) {
      // case "active":
      case "threadCase":
      case "matchedSystem":
      case "datasheet":
      case "select":
      case "create":
      case "environmentalLink":
      case "materialDeclaration":
      case "createManufacturer":
      case "delete":
      case "createThread":
      case "checkedByOm":
      case "preferred":
      case "valid":
      case "partsToVehicles":
      case "pcnSource":
      case "createCase":
      case "seen":
      case "passwordExpireDate":
      case "organizationProfile.Firma/Mandant":
      case "user.email":
      case "personProfile.Handy":
      case "personProfile.PLZ Ort":
      case "change.seen":
      case "pcn.seen":
      case "lastNote":
      case "assignedTo":
      case "part.statusAlerts":
      case undefined:
        return false;
      default:
        return true;
    }
  }

  saveFilterResult(docType: string, result: Manufacturer[] | Part[]) {
    if (docType === "manufacturer") {
      const manufacturers = result as Manufacturer[];
      let ids = new Set<string>();
      manufacturers.forEach((m) => {
        if (m._id != null) {
          ids.add(m._id);
        }
      });
      this.app.manufacturersFilter.currentIds = Array.from(ids);
    }
    if (docType === "part") {
      this.app.part.currentAssemblies = result as Part[];
    }
  }

  displayFilterTable(column: string) {
    // display filter on column only if column can be filtrated
    if (this.getFilterTableColumns(column)) {
      // display filter only if there are any results in se-filter/manufacturerMatching page
      if (
        (this.app.seFilter.matches.length === 0 &&
          this.app.view === "se-filter" &&
          !this.filterFocus) ||
        (this.app.manufacturerMatching.manufacturer.matches &&
          this.app.manufacturerMatching.manufacturer.matches.length === 0 &&
          !this.filterFocus)
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  private filterOptionValueAsText(
    docs: any,
    fieldId: string,
    fieldName: string,
    filterValue: string
  ) {
    const result: any[] = [];
    const isFieldMultiple = this.app.field.isMultiple(fieldId);

    if (filterValue === "") {
      return docs;
    }

    docs.filter((element: any) => {
      let fieldValueAsText = this.app.field.getFieldValueAsText(
        fieldId,
        element[fieldName]
      );
      const emptyValueAsText = this.getEmptyValueAsText(
        fieldName,
        element[fieldName]
      );
      const isOptionAsValue = this.getOptionsAsValue(fieldName);

      /** Special case for user.roles, the value coming from the database contains the client name and role. ex: ['demo-user']  */
      if (fieldId === "user.roles") {
        if (element[fieldName] == null) {
          return docs;
        }
        /** element[fieldName] will always return only one role; user can only have one role for a client in the real environment */
        const role = element[fieldName][0].split("-")[1];
        const roleAsTranslatedText = this.app.field.getOptionText(
          this.app.fieldId.user.roles,
          role
        );
        if (roleAsTranslatedText === filterValue) {
          fieldValueAsText = filterValue;
        }
      }

      if (fieldId === "user.active") {
        if (fieldValueAsText && filterValue === "Active") {
          fieldValueAsText = filterValue;
        } else if (!fieldValueAsText && filterValue === "Offline") {
          fieldValueAsText = filterValue;
        }
      }

      if (
        fieldValueAsText === filterValue ||
        emptyValueAsText === filterValue
      ) {
        result.push(element);
      } else if (isFieldMultiple && fieldValueAsText.includes(filterValue)) {
        result.push(element);
      } else if (isOptionAsValue && element[fieldName] === filterValue) {
        result.push(element);
      }
    });
    return result;
  }

  private filterTimestampValueAsText(
    docs: any,
    fieldName: string,
    filterValue: string
  ) {
    const result: any[] = [];
    if (filterValue === "") {
      return docs;
    }

    docs.filter((element: any) => {
      if (element[fieldName] != null) {
        // FormatDatetime return the following format: "YYYY-MM-DD, HH:MM:SS"
        let date = formatDatetime(element[fieldName]);

        if (fieldName === "create_time") {
          // Erased "," from the date variable
          // In this case users have the possibility to filter by YYY-MM-DD HH:MM:SS
          date = date.replace(",", "");
        } else {
          // Remove everything after character "," from the date variable
          // In this case users have the possibility to filter only by YYY-MM-DD
          date = date.substring(0, date.indexOf(","));
        }

        if (date.indexOf(filterValue.toLowerCase()) !== -1) {
          result.push(element);
        }
      }
    });
    return result;
  }

  private getEmptyValueAsText(fieldName: string, fieldValue: string) {
    switch (fieldName) {
      case "taskCompleted":
        if (StringUtils.isNullOrEmpty(fieldValue)) {
          return this.app.field.getFieldValueAsText("post." + fieldName, false);
        }
      case "obsolescenceStatus":
      case "obsolescenceStatus2years":
      case "obsolescenceStatus4years":
      case "obsolescenceStatus6years":
      case "obsolescenceStatus8years":
      case "likelihood":
      case "euRoHS":
      case "reachAffected":
      case "leadTime":
      case "euRoHSStatus":
      case "obsolescenceStatus":
        if (StringUtils.isNullOrEmpty(fieldValue)) {
          return this.app.text.RM.withoutData;
        }
      default:
        return "";
    }
  }

  getOptions(fieldId: string) {
    if (this.fieldsWithEmptyOption.includes(fieldId)) {
      let options = this.app.field.getOptions(fieldId);
      if (fieldId === "part.obsolescenceStatus") {
        let optionsToRemove = options.filter(
          (option) => option === "resolved" || option === "unconfirmed"
        );
        options = options.filter((o) => optionsToRemove.indexOf(o) === -1);
      }
      // added a new option, withoutData, that checks if the field contains empty cells
      options.push(WITHOUT_DATA);
      return options;
    } else {
      return this.app.field.getOptions(fieldId);
    }
  }
}
