import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { Item } from "../../../../shared/models/item";
import { getDocById } from "../api.service";
import { getApp } from "../app";

@Component({
  selector: "app-internal-items",
  templateUrl: "./internal-items.component.html",
  styleUrls: ["./internal-items.component.scss"],
})
export class InternalItemsComponent implements OnInit {
  currentItemIndex: number | null = null;

  focusInput = false;

  item = new Item();

  newItemSubscription: Subscription = new Subscription();

  currentItem: Item = {} as Item;

  @ViewChild("fileInput", { static: true }) fileInput: any;

  app = getApp((app) => {
    this.app = app;
  });

  columns: string[] = [];
  cleanColumns: string[] = [];

  constructor() {}

  async ngOnInit() {
    this.app.type = "internalItems";
    this.app.internalItems.items = [];
    await this.app.internalItems.getInternalItems();

    this.columns = this.app.list.internalItem.columns;
    this.cleanColumns = this.app.list.internalItem.columns.map(
      (i: string) => i.split(".")[1]
    );
    if (!this.app.permission.impact.canAddImpactToCase) {
      let index = this.cleanColumns.findIndex(
        (column: string) => column === "actions"
      );
      let indexColumn = this.columns.findIndex(
        (column: string) => column === "impact.actions"
      );
      this.cleanColumns.splice(index, 1);
      this.columns.splice(indexColumn, 1);
    }

    this.newItemSubscription = this.app.internalItems.newItemSubject.subscribe(
      async (value: boolean) => {
        if (value) {
          await this.app.internalItems.getInternalItems();
          this.app.state.pageSize = 10;
        }
        if (this.app.internalItems.items.length === 0) {
          this.item = new Item();
        }
      }
    );
    if (this.app.internalItems.items.length === 0) {
      this.app.internalItems.addNewItem();
      this.focusInput = true;
    }
  }

  async clearItem() {
    let item = this.app.internalItems.currentItem;
    //if item.part._id != null it means that the internalItem is not saved in the database
    if (item.part._id == null) {
      this.deleteItem();
      return;
    }
    let doc = await getDocById(this.app.customers.expectCurrent, item.part._id);
    item.part = doc;
    item.isEditable = false;
    this.focusInput = false;
  }

  setEditable(item: any) {
    item.isEditable = !item.isEditable;
  }

  deleteItem() {
    this.item = new Item();
  }

  isDisabled(item: Item) {
    if (item.part.partNumber == null || item.part.partNumber === "") {
      return true;
    }
    return false;
  }

  async upload() {
    let files: FileList = this.fileInput.nativeElement.files;
    this.app.import.type = "internalItem";
    await this.app.importXlsx(files);
    await this.app.import.prepareDocs();
  }

  ngOnDestroy(): void {
    if (this.newItemSubscription) {
      this.app.state.pageSize = 25;
      this.newItemSubscription.unsubscribe();
    }
  }
}
