import { ComponentSettings, empty } from "../settings/settings";

const settings = {
  text: {
    createMatch: {
      de: "Zuordnen",
      en: "Match",
      nl: "Match",
    },
    deleteMatch: {
      de: "Zuordnung aufheben",
      en: "Unmatch",
      nl: "Unmatch",
    },
    datasheet: {
      de: "Datenblatt",
      en: "Datasheet",
      nl: "Datasheet",
    },
    select: {
      de: "Auswählen",
      en: "Select",
      nl: "Selecteren",
    },
    selected: {
      de: "Ausgewählt",
      en: "Selected",
      nl: "Toegewezen",
    },
    rohs: {
      de: "RoHS",
      en: "RoHS",
      nl: "RoHs",
    },
    originalData: {
      de: "Kundendaten (Import)",
      en: "Client Data (Import)",
      nl: "Client Data (Import)",
    },
    currentMatch: {
      de: "Aktuelle Zuweisung",
      en: "Current Match",
      nl: "Current Match",
    },
    noResults: {
      de: "Keine Ergebnisse gefunden",
      en: "No results found",
      nl: "Geen resultaten",
    },
    unmatch: {
      en: "Unmatch",
      de: "Zuweisung aufheben",
      nl: "Unmatch",
    },
    matchingStatus: {
      en: "Status",
      de: "Status",
      nl: "Status",
    },
    edited: {
      en: "Edited",
      de: "Bearbeitet",
      nl: "Bewerkt",
    },
    matchingItem: {
      en: "Matching items",
      de: "Einheiten verbinden",
      nl: "Matching artikelen",
    },
    matching: {
      en: "Matching",
      de: "Einheiten",
      nl: "Matching",
    },
  },
  field: {},
  url: {},
  permission: {},
  list: {
    columns: empty,
    columnRows: empty,
  },
};

export const matchingManufacturer: ComponentSettings<typeof settings> =
  settings;
